import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import {Helmet} from "react-helmet";
import {PrimaryButton} from '../Elements/primary-button';
import { SecondaryButton } from "../Elements/secondary-button";
import {PopOver} from '../Elements/popover';
import Modal from '../Elements/modal';
import {NotificationBar} from '../Elements/notification-bar';

class News extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uuid: undefined,
            editable: false,
            news: [],
            news_url: '',
            news_type: '',
            page_number: 0,
            scrolling: false,
            paginate: false,
            news_count: 0,
            news_category: '',
            news_input_visible: false,
            modal_visible: null,
            notification_visible: false,
            success: false,
            api_response_message: ''
        };
      }
      get_news(type= "paginate") {
        axios
          .post(`${process.env.REACT_APP_API_URL}/news`, {
            type:type,
            page: this.state.page_number,
            category: this.state.news_category
          })
          .then((res) => {
            let paginate_data = false;
            if (res.data.length > 0) {
              paginate_data = true;
            }
            if (type == "paginate") {
              this.setState({
                news: res.data[0],
                paginate: paginate_data,
                scrolling: false,
                news_count: res.data[1]
              });
            } else {
              var all_news = this.state.news
              for (var news of res.data[0]) {
                all_news.push(news)
              }
              this.setState({
                news: all_news,
                paginate: paginate_data,
                scrolling: false
              });
            } 
            console.log(this.state.news)
          });
      }
        componentDidMount() {
            this.get_news()
            window.addEventListener("scroll", this.handleScroll.bind(this));
            }
            handleScroll() {
              if (this.state.paginate === true) {
                if (document.querySelector(".newsList__grid")) {
                  if (
                    $(window).scrollTop() >=
          $(".newsList__grid").offset().top +
            $(".newsList__grid").outerHeight() -
            window.innerHeight
                  ) {
                    if (this.state.scrolling === false) {
                      this.setState(
                        { page_number: this.state.page_number + 1, scrolling: true },
                        function () {
                          if (this.state.news.length < this.state.news_count)
                          this.get_news("page");
                        }
                      );
                    }
                  }
                }
              }
            }
        remove(uuid) {
                axios.post(`${process.env.REACT_APP_POST_URL}/remove`, {uuid: uuid, table: 'news'}).then((res) =>{
                  window.location = ''
                })
              }
    componentWillUnmount() {

    }
    uploadNews(news_tag) {
          let data = {
              news_url: this.state.news_url,
              news_type: this.state.news_type,
              uuid: this.state.uuid,
              category: news_tag
          }
          axios.post(`${process.env.REACT_APP_POST_URL}/news/add_news`, data).then((res) => {
            console.log(res)
            if (res.status == 200) {
              this.setState({notification_visible: true, success: true, api_response_message: 'Saved successfully'})
        setTimeout(() => {
          this.setState({notification_visible: false}, () => {
            window.location = ''
          })
        }, 3000)
            }
            else {
              this.setState({notification_visible: true, success: false, api_response_message: res.statusText})
        setTimeout(() => {
          this.setState({notification_visible: false}, () => {
          })
        }, 3000)
            }
          }).catch((err) => {
            this.setState({notification_visible: true, success: false, api_response_message: 'Internal server error'})
        setTimeout(() => {
          this.setState({notification_visible: false}, () => {
          })
        }, 3000)
          })
      }
      modalHandler(index) {
        this.setState({ modal_visible: this.state.modal_visible !== index ? index : null })
      }
    render() {
      var title = 'News — Flybot Studios'
      return(
          <div className="content--wrapper">
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <div className="main--container">
          <NotificationBar style={{position: 'absolute', width: '100%', top: '0', left: '0', right: '0', zIndex: '999'}} visible={this.state.notification_visible} success={this.state.success}>{this.state.api_response_message}</NotificationBar>
          <div style={{borderBottom: '1px solid #e3e4e5' }} className="newsHeader">
            <div style={{display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'space-between'}} className="newsHeader__title">
              <div className="page_heading_wrapper">
              <h1 style={{margin: '0'}}>News</h1>
              <div className="page_heading_button_s_container">
              {
                  this.state.news_url.trim() == '' ?
                  (
                    <PrimaryButton onClick={() => this.setState({news_input_visible: true})} type={'button'}>Add</PrimaryButton>
                  )
                  :
                  (
                    <PopOver function_parameter={true} option_one_function={this.uploadNews.bind(this)} option_two_function={this.uploadNews.bind(this)} option_three_function={this.uploadNews.bind(this)} option_four_function={this.uploadNews.bind(this)} options={[{name: 'Design', function_parameter: 'Design'}, {name: 'Advertising', function_parameter: 'Advertising'}, {name: 'Development', function_parameter: 'Development'}, {name: 'Any', function_parameter: ''}]} type={'button'}>Save</PopOver>
                  )
                }

              </div>
              </div>
              <div className="function_wrapper">
                    <div style={{width: '100%'}} className="post_news_container">
              
              <div style={{paddingBottom: '2px', width: '100%'}} className="news-post-wrapper">
            {/* <input list="projects" onInput={(e)=> this.setState({project_name: e.target.value})} className="news_link_input" type="text" placeholder="Project Name" /> */}
            {
              this.state.news_input_visible == true &&
              <input onInput={(e)=> this.setState({news_url: e.target.value})} className="post_input_field" type="text" placeholder="Paste Link" />
            }
            {/* <button style={{border: 'none', width: '66%', padding: '0'}} type="submit" class="btn btn-outline-primary"> Add <span>{this.state.upload_type == 'Carousel' ? 'Carousel' : 'Highlight'}</span> </button> */}
        </div>
   
              </div>
        
              <div className="page_heading_button_l_container" style={{width: '100%', textAlign: 'right'}}>
                {
                  this.state.news_url.trim() == '' ?
                  (
                    <PrimaryButton onClick={() => this.setState({news_input_visible: true})} type={'button'}>Add</PrimaryButton>
                  )
                  :
                  (
                    <PopOver function_parameter={true} option_one_function={this.uploadNews.bind(this)} option_two_function={this.uploadNews.bind(this)} option_three_function={this.uploadNews.bind(this)} option_four_function={this.uploadNews.bind(this)} options={[{name: 'Design', function_parameter: 'Design'}, {name: 'Advertising', function_parameter: 'Advertising'}, {name: 'Development', function_parameter: 'Development'}, {name: 'Any', function_parameter: ''}]} type={'button'}>Save</PopOver>
                  )
                }

              </div>
              </div>
            </div>
          </div>
          <div className="newsList">
          <div className="newsList__grid">
          {
              this.state.news.length > 0 &&
              this.state.news.sort((a,b) => new Date(b.updated_at) - new Date(a.updated_at)).map((item, index) => {
                  return (
                    <a target="_blank" href={item.url}>
                      <Modal shown={this.state.modal_visible == index ? true : false} close={() => this.modalHandler(index)}>
                                          <Modal.Header>Confirmation</Modal.Header>
                                          <Modal.Body>Are you sure you want to delete {item.title}?</Modal.Body>
                                          <Modal.Footer>
                                            <PrimaryButton onClick={() => this.remove(item.uuid)} style={{ float: 'right' }}>Save</PrimaryButton>
                                            <SecondaryButton onClick={() => { this.modalHandler(index) }} >Cancel</SecondaryButton>
                                          </Modal.Footer>
                                        </Modal>
                    <div key={index} className="newsList__item">
          <div className="news__cover">
          <div style={{position: 'absolute', width: '100%', top: '0', textAlign: 'right', padding: '1%', background: 'rgba(0,0,0,0.1)'}}>
                                <button onClick={(e)=> { e.preventDefault(); e.stopPropagation(); this.modalHandler(index)}} className="remove-button">
                                <svg height="20px" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336"/><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa"/></svg>
                                </button>
                                </div>
          <img style={{objectFit: 'unset', height: 'unset'}} src={item.image} />
          </div>
          <div className="news__title">
          <h2 className="newsItem__title">{item.title} <span><svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 128 128" width="20px" height="20px"><path d="M 84 11 C 82.3 11 81 12.3 81 14 C 81 15.7 82.3 17 84 17 L 106.80078 17 L 60.400391 63.400391 C 59.200391 64.600391 59.200391 66.499609 60.400391 67.599609 C 61.000391 68.199609 61.8 68.5 62.5 68.5 C 63.2 68.5 63.999609 68.199609 64.599609 67.599609 L 111 21.199219 L 111 44 C 111 45.7 112.3 47 114 47 C 115.7 47 117 45.7 117 44 L 117 14 C 117 12.3 115.7 11 114 11 L 84 11 z M 24 31 C 16.8 31 11 36.8 11 44 L 11 104 C 11 111.2 16.8 117 24 117 L 84 117 C 91.2 117 97 111.2 97 104 L 97 59 C 97 57.3 95.7 56 94 56 C 92.3 56 91 57.3 91 59 L 91 104 C 91 107.9 87.9 111 84 111 L 24 111 C 20.1 111 17 107.9 17 104 L 17 44 C 17 40.1 20.1 37 24 37 L 69 37 C 70.7 37 72 35.7 72 34 C 72 32.3 70.7 31 69 31 L 24 31 z"/></svg></span></h2>
          <p className="newsItem__detail">{`${item.site_name != null ? item.site_name + ' —' : ''}  ${new Date(item.date_of_publish).toDateString()}`}</p>
          </div>
          </div>
          </a>
                  )
              })
          }
          </div>
          </div>
          </div>
          </div>
      )
    }
  }

  export default News;