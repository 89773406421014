import React from "react";
import styled from 'styled-components';
const Button = styled.button`
position: relative;
height: 48px;
min-width: 80px;
padding: 0 16px;
border-radius:  6px;
font-size: 14px;
font-weight: 500;
text-align: center;
transition: background-color .2s ease-in-out,color .2s ease-in-out,opacity .2s ease-in-out;
user-select: none;
cursor: pointer;
background: #006AFF;
line-height: 48px;
color: #fff;
border: none;
&:hover {
    background: #0055CC;
    color: #fff;
}
&:focus {
    background: #0055CC;
    color: #fff;
    outline: none;
    border: none
}
`


export const PrimaryButton = ({children, className, style, onClick, type}) => {
    return <Button type={type == 'submit' ? 'submit' : 'button'} onClick={onClick} style={style} className={className}>{children}</Button>
}

