import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        carousel_uuid: undefined,
    };

  }

  projects_data() {

  }
  

uploadFile(e) {
  e.preventDefault();
    this.setState({selectedFile: e.target.files})
    var file_names = []
    for (var i = 0; i < e.target.files.length; i++) {
        file_names[i] = (e.target.files[i].name)
    }
    console.log(file_names)
    var rows = document.querySelectorAll('.row_content')
    rows[this.state.number_of_rows].querySelector('input[name="content_file_values"]').value = JSON.stringify(file_names)
    console.log(rows[this.state.number_of_rows].querySelector('input[name="content_file_values"]').value)

    var formData = new FormData();
    var number_of_files = e.target.files.length;
    console.log(number_of_files)
    for (var index=0; index < number_of_files; index++) {
        formData.append("inputPostFile", e.target.files[index])
    }
    console.log(formData)
    axios.post(`${process.env.REACT_APP_POST_URL}/projects/upload_content`, formData).then((res)=> {
        console.log(res.data)
        var image_elements = rows[this.state.number_of_rows].querySelectorAll('img')
        for (var i = 0; i < file_names.length; i++) {
            image_elements[i].src = `${process.env.REACT_APP_ASSETS_URL}/${file_names[i]}`
        }
    })
}
  componentDidMount() {
      var urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("uuid") != undefined) {
        this.carousel_data();
      }
  }

  render() {
    return (
      <div className="content--wrapper">
        <div className="main--container">         
          <div className="workFeatureHeader">
            <div className="workFeatureHeader__title">
              <h1><input onInput={(e) => { this.setState({ title: e.target.value }); }} className="project-form-field" id="workFeatureTitle" type="text" name="title" placeholder="Title" /></h1>
            </div>
            <div className="workFeatureHeader__subtitle">
              <h4><input onInput={(e) => {this.setState({sub_title: e.target.value})}} className="project-form-field" id="workFeatureSubTitle" type="text" name="subtitle" placeholder="Sub Title" /></h4>
            </div>
          </div>
          <div style={{position: 'relative'}} className="row_content">
          <button className="edit--btn">
          <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">    <path d="M 18 2 L 15.585938 4.4140625 L 19.585938 8.4140625 L 22 6 L 18 2 z M 14.076172 5.9238281 L 3 17 L 3 21 L 7 21 L 18.076172 9.9238281 L 14.076172 5.9238281 z" /></svg>

          </button>
          <input type="hidden" name="content_file_values"/>
            <img
              src={process.env.PUBLIC_URL + `/img/029_Everlasting_Sky.png`}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Carousel;
