import React, { Component } from "react";
import $ from 'jquery';
import { Link, NavLink } from "react-router-dom";
import {PrimaryButton} from '../Elements/primary-button';

class WorkHeader extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
      }
    }
  componentDidMount() {
  }
    render(){
        return (
          <React.Fragment>
            <div style={{borderBottom: '1px solid #e3e4e5' }} className="newsHeader">
            <div style={{display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'space-between'}} className="newsHeader__title">
              <div className="page_heading_wrapper">
              <h1 style={{margin: '0'}}>Work</h1>
              <div className="page_heading_button_s_container">
                <Link to="/projects/add_project">
                <PrimaryButton type={'button'}>Add</PrimaryButton>
                </Link>
              </div>
              </div>
              <div className="function_wrapper">
              <div className="page_heading_button_l_container" style={{width: '100%', textAlign: 'right'}}>
                <Link to="/projects/add_project">
                <PrimaryButton type={'button'}>Add</PrimaryButton>
                </Link>
              </div>
              </div>
            </div>
          </div>
    </React.Fragment>
        )
    }
}

export default WorkHeader