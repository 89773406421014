import React from "react";
import Switch from "react-switch";
import { useMediaQuery } from "./use_media_query";

export const SwitchButton = ({ list_view, view_change }) => {
    const isSmall = useMediaQuery('(max-width: 767px)')
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
                <Switch checkedIcon={
                    false
                } uncheckedIcon={false} width={35} height={20} offColor={'#f2f2f2'} onColor={'#006AFF'} onChange={() => view_change()} checked={list_view} />
                {

                       (list_view == true ? 'List View' : 'Grid View')
                }
            </label>
        </div>
    )
}
