import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import Cookie from 'js-cookie';
import {Helmet} from "react-helmet";
var config = {
  headers: {
      'Authorization': 'flybot_studios9114403471',
  }
}
class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          username: '',
          password: '',
          logged_in: false,
          error: false,
          msg: ''
        };
      }
      login_form(event) {
        event.preventDefault();
        let data = {
            username: this.state.username,
            password: this.state.password
        };
        axios.post(`${process.env.REACT_APP_API_URL}/login`, data, config).then((res) => {
            if (res.status === 200) {
                Cookie.set('flybot_root_user', res.data.token, { expires: 7, path: '/' });
                this.setState({ logged_in: true, error: false, msg: res.data.msg});
                window.location = res.data.redirect_url
            } else {
                this.setState({ logged_in: false, error: true, msg: res.data.msg});
                alert(res.data.msg)
            }
        }).catch((e) => {
            console.log(e);
            this.setState({ logged_in: false, error: true, msg: 'Error'});
            alert('Error')
        });
      }
        componentDidMount() {

            }
    componentWillUnmount() {

    }
    render() {
      var title = 'Admin — Flybot Studios'
      return(
          <div className="content--wrapper">
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <div className="main--container">
          <form onSubmit={this.login_form.bind(this)}>
          <div className="workFilter">
      <div className="workFilterForm">
        <h2 style={{position: 'unset'}} className="adminHeader">Admin Panel</h2>
        <div className="workFilterWrapper">
        </div>
      </div>
    </div>
    <div style={{position:'relative', width: '100%'}}>
    <div style={{padding: '5% 0%'}}>
    <div style={{width: '100%'}}>
    <div className="sign-in-input-wrapper">
    <input type="text" onInput={(e) => this.setState({username: e.target.value})} className="project-form-field sign-in-field" placeholder="Username" />
    </div>
    </div>
    <div style={{width: '100%'}}>
    <div className="sign-in-input-wrapper">
    <input type="password" onInput={(e) => this.setState({password: e.target.value})} className="project-form-field sign-in-field" placeholder="Password" />
    <div style={{position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)'}}>
    </div>
    </div>
    </div>
    <div className="sign-in-btn-container">
        <button type="submit" className="sign-in-btn">Sign In</button>
    </div>
    </div>
    </div>
    </form>
          </div>
          </div>
      )
    }
  }

  export default SignIn;