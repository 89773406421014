/* 
  filename: Dropzone.js 
*/

import React, {createRef, useEffect} from "react";
// Import the useDropzone hooks from react-dropzone
import Dropzone, { useDropzone } from "react-dropzone";



const DropzoneComponent = ({ onDrop, accept, maxSize, onDropRejected, children, noClick, textHidden, dropzone_open_index, index}) => {
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept,
    onDropRejected,
    maxSize,
    noClick
  });

  useEffect(() => {
    if (dropzone_open_index !== undefined && index !== undefined) {
      if (dropzone_open_index == index) {
        open()
      }
    }
    
  },[dropzone_open_index])

  return (
    <React.Fragment>
<div {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div style={{display: textHidden == true ? 'none' : 'block'}} className="text-center">
        {isDragActive ? (
          <p className="dropzone-content">Release to drop the files here</p>
        ) : (
          <p className="dropzone-content">
            Drag 'n' drop your files here, or click Upload
          </p>
        )}
      </div>
      {children}
    </div>
   
    </React.Fragment>
    
  );
};


export default DropzoneComponent;