import React from "react";
import styled from 'styled-components';

const BannerFlash = styled.div`
background: ${props => (props.success == true ? '#00B23B' : '#CC0023')};
height: ${props => (props.visible == true ? 'auto' : '0')};
opacity: ${props => (props.visible == true ? '1' : '0')};
transition: ${props => (props.visible == true ? 'opacity .2s linear,background-color .2s ease-out' : 'opacity .2s linear')};
overflow: hidden;
color: #fff;
position: relative;
svg {
    width: 18px;
    height: 18px;
    fill: #fff;
};
box-shadow: 0 4px 8px rgb(0 0 0 / 10%), 0 2px 16px rgb(0 0 0 / 10%);
`
const BannerFlashWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 10px 20px;

`

export const NotificationBar = ({success, visible ,children, className, style }) => {
    return (
        <BannerFlash style={style} success={success} visible={visible} className={className}>
            <BannerFlashWrapper>
            {
                success == true ?
                    (
                        <svg role="img" viewBox="0 0 16 16">{/**/}<path fillRule="evenodd" clipRule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0968 6.31744 12.0978 5.68597 11.7093 5.29509C11.3208 4.90422 10.6894 4.90128 10.2973 5.28852L11 6C10.2973 5.28852 10.2973 5.28853 10.2973 5.28856L10.2971 5.28866L10.2967 5.28908L10.2951 5.29071L10.2886 5.29714L10.2632 5.32224L10.166 5.41826L9.81199 5.76861C9.51475 6.06294 9.10795 6.46627 8.66977 6.90213C8.11075 7.4582 7.49643 8.07141 6.99329 8.57908L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z" fillOpacity="0.9"/>
                        </svg>

                    )
                    :
                    (
                        <svg role="img" viewBox="0 0 16 16">{/**/}<path fillRule="evenodd" clipRule="evenodd" d="M0.40911 13.7584L7.56222 0.79348C7.75236 0.448865 8.24767 0.448865 8.4378 0.793481L15.5909 13.7585C15.7747 14.0917 15.5337 14.5 15.1531 14.5H0.846898C0.466303 14.5 0.225253 14.0917 0.40911 13.7584ZM7.99999 9.00145C7.44771 9.00145 6.99999 8.55373 6.99999 8.00145V6.00187C6.99999 5.44958 7.44771 5.00187 7.99999 5.00187C8.55228 5.00187 8.99999 5.44958 8.99999 6.00187V8.00145C8.99999 8.55373 8.55228 9.00145 7.99999 9.00145ZM7.99999 10.001C7.44771 10.001 6.99999 10.4486 6.99999 11.0008C6.99999 11.553 7.44771 12.0006 7.99999 12.0006C8.55228 12.0006 8.99999 11.553 8.99999 11.0008C8.99999 10.4486 8.55228 10.001 7.99999 10.001Z" fillOpacity="0.9" />
                        </svg>

                    )
            }
            <div style={{padding: '0 10px', fontWeight: '500', lineHeight: '1.57', flex: '0 1 auto'}}>
            {children}
            </div>
            </BannerFlashWrapper>
            
        </BannerFlash>
    )

}


