
import ReactDOM from 'react-dom'
import React from "react";
import {PrimaryButton} from '../Elements/primary-button';


export class PopOver extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popover_visible: false,
        };
    }
    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                popover_visible: false
            });
        }
    }
    render() {
        return (
            <div style={{ position: 'relative' }}>
                <PrimaryButton onClick={() => this.setState({ popover_visible: !this.state.popover_visible })}>{this.props.children}</PrimaryButton>
                {
                    <div className={`popover_wrapper ${this.state.popover_visible == true ? 'popover_wrapper_active' : null}`} ref={this.state.popover_visible}>
                        {
                            this.props.options.map((item, index) => {
                                return (
                                    <a className="popover_option" onClick={() => index == 0 ? this.props.option_one_function(this.props.function_parameter == true ? item.function_parameter : null) : index == 1 ? this.props.option_two_function(this.props.function_parameter == true ? item.function_parameter : null) : index == 2 ? this.props.option_three_function(this.props.function_parameter == true ? item.function_parameter : null) : index == 3 ? this.props.option_four_function(this.props.function_parameter == true ? item.function_parameter : null) : null} key={index}>{item.name}</a>
                                )
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}


