import React from "react";
import ReactDOM from 'react-dom';

const Row = ({children, onClick, active}) => {
    let subComponentList = Object.keys(Row);

    let subComponents = subComponentList.map((key) => {
        return React.Children.map(children, (child) => 
        child.type.name == key ? child : null
        );
    });

    return  (
        <React.Fragment>
                <div  className={active == true ? 'row_wrapper content_row_active' : 'row_wrapper'}>
                {subComponents.map((component) => component)}
                </div>
        </React.Fragment>
    ) 
};

const Header = (props) => <div className="row_header">{props.children}</div>
Row.Header = Header

const Body = (props) => <div className="row_body">{props.children}</div>
Row.Body = Body

const Footer = (props) => <div className="row_footer">{props.children}</div>
Row.Footer = Footer

export default Row;