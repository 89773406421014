import React, { Component } from "react";
import $ from "jquery";
import { Link, NavLink, Switch } from "react-router-dom";
import WorkHeader from "./Work-Header";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SwitchButton } from "../Elements/switch-button";
import Modal from "../Elements/modal";
import { PrimaryButton } from "../Elements/primary-button";
import { SecondaryButton } from "../Elements/secondary-button";

class AllProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      projects_count: 0,
      page_number: 0,
      tags: [],
      url: window.location.pathname.split("/").slice(3, 4).toString(),
      project_type: window.location.pathname.split("/").splice(2, 1).toString(),
      paginate: false,
      scrolling: false,
      list_view: false,
      modal_visible: null
    };
  }
  view_change() {
    this.setState({ list_view: !this.state.list_view })
  }
  modalHandler(index) {
    this.setState({ modal_visible: this.state.modal_visible !== index ? index : null })
  }
  get_projects(type = "paginate") {
    let tag_query;
    if (this.state.project_type == 'disciplines') {
      tag_query = 'type_of_work'
    }
    else if (this.state.project_type == 'sector') {
      tag_query = 'type_of_client'
    }
    axios.post(`${process.env.REACT_APP_API_URL}/projects`, { url: this.state.url, tag_query: tag_query, type: type, page: this.state.page_number, all_projects: true }).then((res) => {
      let paginate_data = false;
      if (res.data.length > 0) {
        paginate_data = true;
      }
      if (type == "paginate") {
        this.setState({
          projects: res.data[0],
          paginate: paginate_data,
          scrolling: false,
          projects_count: res.data[1]
        });
      }
      else {
        var all_projects = this.state.projects
        for (var project of res.data[0]) {
          all_projects.push(project)
        }
        this.setState({
          projects: all_projects,
          paginate: paginate_data,
          scrolling: false
        })
      }
      // this.setState({ projects: res.data[0] });
    });
  }
  remove(uuid) {
    axios.post(`${process.env.REACT_APP_POST_URL}/remove`, { uuid: uuid, table: 'projects' }).then((res) => {
      console.log(res)
      window.location = ''
    })
  }
  handleScroll() {
    if (this.state.paginate === true) {
      if (document.querySelector(".workFeature-row")) {
        if (
          $(window).scrollTop() >=
          $(".workFeature-row").offset().top +
          $(".workFeature-row").outerHeight() -
          window.outerHeight
        ) {
          if (this.state.scrolling === false) {
            this.setState(
              { page_number: this.state.page_number + 1, scrolling: true },
              function () {
                if (this.state.projects.length < this.state.projects_count)
                  this.get_projects("page");
              }
            );
          }
        }
      }
    }
  }
  // get_tags() {
  //   axios
  //     .post(`http://localhost:3001/api/tags`, {
  //       tag_type: "Client",
  //     })
  //     .then((res) => {
  //       this.setState({
  //         tags: res.data,
  //       });
  //       console.log(res.data)
  //     });
  // }
  componentDidMount() {
    this.get_projects();
    // this.get_tags();
    console.log(this.state.url)
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  handleOnDragEnd(result, source) {
    if (!result.destination) {
      return
    }
    const projects_list = this.state.projects
    if (source == 'projects') {
      const [re_ordered_projects_list] = projects_list.splice(result.source.index, 1)
      projects_list.splice(result.destination.index, 0, re_ordered_projects_list)
      var ordered_list_props = []
      this.setState({ projects: projects_list }, () => {
        this.state.projects.map((project, index) => {
          ordered_list_props.push({ uuid: project.uuid, index: index })
        })
        this.updateSort(ordered_list_props, source)
      })
    }
  }

  async updateSort(list_props, source) {
    await axios.post(`${process.env.REACT_APP_POST_URL}/sort_order`, { table: source, list_props: list_props, archive_sort: true }).then((res) => {
      if (res.status == 200) {
        console.log('Sorted')
      }
      else {
        console.log('Error while sorting list')
      }
    }).catch(e => {
      console.log(e)
    })
  }
  render() {
    return (
      <section className="workArchive">
        <div className="workFeature-row">
          <div className="itemHeader">
            <h2>
              <span className="itemHeaderTitle">All Projects</span>{" "}
              <span className="item-counter">
                {this.state.projects_count}
              </span>
            </h2>
            <div>
              <SwitchButton view_change={this.view_change.bind(this)} list_view={this.state.list_view} />
            </div>
          </div>
          {
            this.state.list_view == true ?
              (
                <div className="numbered-list">
                  <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, 'projects')}>
                    <Droppable droppableId="highlightsList">
                      {
                        (provided) => (
                          <ul {...provided.droppableProps} ref={provided.innerRef}>
                            {
                              this.state.projects.map((value, index) => {
                                return (
                                  <React.Fragment>
                                    <Draggable key={index} draggableId={`${index}`} index={index}>
                                    {
                                      (provided) => (
                                        <div className="draggable-list-container" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                          <li>{value.title} </li>
                                          {/* <button onClick={() => this.modalHandler(index)} className="remove-button"><svg height="20px" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336" /><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa" /></svg></button> */}
                                        </div>
                                      )
                                    }
                                  </Draggable>
                                  </React.Fragment>
                                  
                                )
                              })
                            }
                            {provided.placeholder}
                          </ul>
                        )
                      }
                    </Droppable>
                  </DragDropContext>
                </div>
              )
              :
              (
                <div className="projectArchiveGrid">
                  {
                    this.state.projects.map((item, index) => {
                      return (
                        <div className="projectArchiveItem1 view_projects__project">
                          <div style={{ position: 'absolute', width: '100%', top: '0', textAlign: 'right', padding: '1%', zIndex: '1' }}>
                            <button style={{ position: 'relative', right: '-10px', top: '-10px' }} onClick={() => this.modalHandler(index)}  className="remove-button">
                              <svg height="20px" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336" /><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa" /></svg>
                            </button>
                          </div>
                          <Link to={`/projects/add_project?name=${item.url}`}>
                            <img style={{ position: 'absolute', top: '0' }}
                              src={`${process.env.REACT_APP_ASSETS_URL}/${item.images != '' ? item.images : '029_Everlasting_Sky.jpg'}`}
                            />
                            <div
                              style={{ position: "absolute", width: "100%" }}
                              className="itemTitle workFeature-title"
                            >
                              <span className="projectArchiveItemTitle">
                                {item.title}
                              </span>
                            </div>
                          </Link>
                          <Modal shown={this.state.modal_visible == index ? true : false} close={() => this.modalHandler(index)}>
                            <Modal.Header>Confirmation</Modal.Header>
                            <Modal.Body>Are you sure you want to delete {item.title}?</Modal.Body>
                            <Modal.Footer>
                              <PrimaryButton onClick={() => this.remove(item.uuid)} style={{ float: 'right' }}>Save</PrimaryButton>
                              <SecondaryButton onClick={() => { this.modalHandler(index) }} >Cancel</SecondaryButton>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      )
                    })

                  }
                </div>
              )
          }
        </div>
      </section>
    );
  }
}

export default AllProjects;
