import React from "react";
import styled from 'styled-components';
import ReactDOM from 'react-dom';

const FormTable = styled.div`
width: 100%;
max-width: 633px;
position: relative;
border: 1px solid #d9d9d9;
border-bottom: 0;
`

const FormLayoutRow = styled.div`
position: relative;
display: flex;
`

const FormField = styled.div`
font-size: 14px;
line-height: 1.5714285714;
display: flex;
width: 100%;
color: #1a1a1a;
border-bottom: 1px solid #d9d9d9;
&:focus-within {
    border-bottom: 1px solid #006AFF;
    .form-field__label {
        background-color: #CCE1FF;
    }
}
`

const Label = styled.label`
margin: 0;
padding: 12px 16px;
width: 200px;
font-weight: 500;
background-color: #f2f2f2;
cursor: default;
display: flex;
justify-content: space-between;
@media(max-width: 810px) {
    width: 40%;
}
`
const InputWrapper = styled.div`
position: relative;
display: flex;
justify-content: space-between;
align-items: center;
width: calc(100% - 200px);
@media(max-width: 810px) {
    width: 60%;
}
`
const Input = styled.input`
border: 0;
margin: 0;
appearance: none;
padding: 0;
padding: 12px 16px;
font-size: 14px;
width: 100%;
background-color: transparent;
line-height: 1.5714285714;
overflow:hidden;
text-overflow: ellipsis;
white-space: nowrap;
&:focus {
    outline: none;
    border: none;
}
`

const TextArea = styled.textarea`
overflow: auto;
vertical-align: top;
appearance: none;
margin: 0;
padding: 0;
border: 0;
font-family: inherit;
&:focus {
    outline: none;
}
padding: 12px 16px;
font-size: 14px;
line-height: 1.5714285714;
border: 0;
height: 114px;
width: 100%;
background: 0 0;
resize: none;
`



const FormLayout = ({children, style}) => {
    let subComponentList = Object.keys(FormLayout);

    let subComponents = subComponentList.map((key) => {
        return React.Children.map(children, (child) => 
        child.type.displayName == key ? child : null
        );
    });

    return  (
        <React.Fragment >
            <FormTable style={style}>
                    {subComponents.map((component) => component)}
            </FormTable>
        </React.Fragment>
    )
};

const FormRow = (props) => <FormLayoutRow style={props.style}><FormField>{props.children}</FormField></FormLayoutRow>
FormRow.displayName = 'FormRow'
FormLayout.FormRow = FormRow

const InputLabel = (props) => <Label className="form-field__label" for={props.for}>{props.children}</Label>
InputLabel.displayName = 'InputLabel'
FormLayout.InputLabel = InputLabel

const InputField = (props) => (<InputWrapper>
<Input required={props.required} autoComplete="__away" disabled={props.disabled} name={props.name} placeholder={props.placeholder} value={props.value} id={props.id} type={props.type} defaultValue={props.defaultValue} onInput={props.onInput} onBlur={props.onBlur} onChange={props.onChange}/>
{/* <button style={{borderRadius: '6px', marginRight: '5px', background: '#f2f2f2'}}>+</button>
<button style={{borderRadius: '6px', background: '#f2f2f2', marginRight: '5px'}}>-</button> */}
</InputWrapper>)
InputField.displayName = 'InputField'
FormLayout.InputField = InputField

const TextAreaField = (props) => (
<InputWrapper>
<TextArea required={props.required} autoComplete="__away" disabled={props.disabled} name={props.name} placeholder={props.placeholder} value={props.value} id={props.id} type={props.type} onInput={props.onInput} onBlur={props.onBlur}/>
</InputWrapper>
)

TextAreaField.displayName = 'TextAreaField'
FormLayout.TextAreaField = TextAreaField


export default FormLayout;