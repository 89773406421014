import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Cookie from 'js-cookie';
import AddProject from "./Components/Project/add-project";
import News from "./Components/News/news";
import Header from "./Components/Header/Header";
import AllProjects from "./Components/Project/View-Projects";
import WorkHeader from "./Components/Project/Work-Header";
import Tags from "./Components/Tags/tags";
import Home from "./Components/Home/home";
import Carousel from "./Components/Carousel/edit-carousel";
import SignIn from "./Components/Sign In/sign-in"
import Team from "./Components/Team/team";
import Settings from "./Components/Settings/settings";
import WebHeader from "./Components/Header/web-header";
import Layout from './Components/Layout/layout';
// import Header from "./Components/"

class App extends React.Component {
  render() {
    console.log(Cookie.get('flybot_root_user'))
    const Loggedin = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) => (
          Cookie.get('flybot_root_user') != undefined
              ? <Component {...props} />
              : <Redirect to={`/`} />
      )} />
  );
    return (

 <Router>
      <Loggedin component={() => (<Layout><AddProject /></Layout>)} exact path="/projects/add_project">
      </Loggedin>
      <Loggedin component={() => (<Layout><AddProject /></Layout>)} exact path="/projects/add_project/:url">
      <Header />
      <AddProject />
      </Loggedin>
      <Loggedin component={() => (<Layout><News /><div id="modal-root"></div></Layout>)} exact path="/news">
      </Loggedin>
      <Loggedin component={() => (<Layout><div className="content--wrapper">
             <div className="main--container">
             <WorkHeader />
             <AllProjects />
             <div id="modal-root"></div>
             </div>
          </div></Layout>)} exact path="/projects">
      </Loggedin>
      <Loggedin component={() => (<Layout><Tags /><div id="modal-root"></div></Layout>)} exact path="/tags">
      </Loggedin>
      <Loggedin component={() => (<Layout><Home /><div id="modal-root"></div></Layout>)} exact path="/home">
      </Loggedin>
      <Loggedin component={() => (<Layout><Team /><div id="modal-root"></div></Layout>)} exact path="/team">
      </Loggedin>
      <Loggedin component={() => (<Layout><Settings /><div id="modal-root"></div></Layout>)} exact path="/settings">
      </Loggedin>
      <Loggedin component={() => (<Layout><Carousel /><div id="modal-root"></div></Layout>)} exact path="/carousel">
      </Loggedin>
      <Route exact path="/" >
      <WebHeader />
      <SignIn />
      </Route>
      </Router>

     
    )
  }
}

export default App;
