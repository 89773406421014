import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $, { isEmptyObject } from "jquery";
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
import { Helmet } from 'react-helmet';
import { PrimaryButton } from '../Elements/primary-button';
import { SecondaryButton } from '../Elements/secondary-button';
import Select from "react-select";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import update from 'immutability-helper';
import Row from '../Elements/project-row';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DropzoneComponent from '../Elements/dropzone';
import ImageList from '../Elements/image-list';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import { NotificationBar } from "../Elements/notification-bar";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

const options = [{
    value: 'full_row_content', label: 'Image'
},
{
    value: 'n/a', label: 'Text'
}
    , {
    value: 'motion', label: 'Youtube Video'
}]

const customStyles = {
    container: (provided, state) => (
        {
            ...provided,
            width: '100%',
            height: `${state.selectProps.price_selection == true ? 'unset' : '100%'}`,
        }
    ),
    control: (provided, state) => (
        {
            ...provided,
            height: `${state.selectProps.price_selection == true ? 'unset' : '100%'}`,
            borderRadius: '0',
            borderBottom: '0',
            border: '0',
            backgroundColor: state.selectProps.price_selection == true ? 'transparent' : 'transparent'
        }
    ),
    valueContainer: (provided, state) => (
        {
            ...provided,
            padding: '0 0 0 16px',

        }
    ),
    indicatorSeperator: (provided, state) => (
        {
            ...provided,
            display: 'none',
            width: '0'
        }
    ),
    menu: (provided, state) => (
        {
            ...provided,
            margin: '0',
            boxShadow: 'none',
            borderRadius: '0',
            border: '1px solid #006AFF',
            width: 'calc(100% + 2px)',
            left: '-1px',
            fontWeight: 'normal',
            fontSize: 'initial'
        }
    ),
    menuList: (provided, state) => (
        {
            ...provided,
            padding: '0',
            borderRadius: '0',
        }
    ),
    singleValue: (provided, state) => (
        {
            ...provided,
            fontWeight: state.selectProps.price_selection == true ? '500' : 'initial'
        }
    ),
    menuPortal: (provided, state) => (
        {
            ...provided,
            zIndex: '9999'
        }
    )
}


class AddProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            project_uuid: undefined,
            project_name: new URLSearchParams(window.location.search).get("name"),
            showContentOptions: false,
            number_of_rows: 0,
            current_row_number: 0,
            current_image_number: 0,
            featured_content_width: 0,
            featured_content_height: 0,
            featured_content_src: '',
            content: '',
            row_type: '',
            content_type: '',
            tags: [],
            type_of_work: '',
            type_of_client: '',
            type_of_work_list: [],
            type_of_client_list: [],
            layout: [],
            data: [],
            title: '',
            subtitle: '',
            featured_content: [],
            row_selected: false,
            selected_row_number: null,
            file_rejected: true,
            dragging: false,
            dragIndex: null,
            saveProgress: 0,
            notification_visible: false,
            api_response_message: '',
            success: false,
            dropzone_open_index: null
        }
        this.videoIdInput = React.createRef()
    }

    componentDidMount() {
        this.modules = {
            toolbar: [
              ['bold', 'italic', 'underline','strike', 'blockquote'],
              ['link'],
            ],
          }
         
          this.formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'link'
          ]
        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("name") != undefined) {
            console.log(urlParams.get("name"))
            this.project_data();
        }
        this.get_tags()
    }

    selectContent() {
        this.setState({ showContentOptions: true })
    }

    uploadOnClickHandler(value1, value2) {
        this.setState({ current_row_number: value1, current_image_number: value2 })
    }

    addRow(e) {
        e.preventDefault()
        e.stopPropagation()
        var layout_data = this.state.layout
        if (this.state.content != '') {
            this.setState({ number_of_rows: this.state.number_of_rows + 1 })
        }
        if (this.state.row_selected == true) {
            layout_data.splice(this.state.selected_row_number + 1, 0, {
                content: '', // image || quote || video || paragraph
                image_type: undefined, // full row || double column || single column || triple column
                quote: '',
                image: [],
                video: '',
                image_dimensions: [],
            })
        } else {
            layout_data.push({
                content: '', // image || quote || video || paragraph
                image_type: undefined, // full row || double column || single column || triple column || motion
                quote: '',
                image: [],
                video: '',
                image_dimensions: []
            })
        }
        this.setState(
            {
                ...this.state,
                layout: layout_data,
                showContentOptions: false,
                selected_row_number: this.state.layout.length == 1 ? 0 : this.state.selected_row_number + 1
            })
    }

    removeRow() {
        if (this.state.row_selected == true) {
            var layout_data = this.state.layout
            layout_data.splice(this.state.selected_row_number, 1)
            this.setState(
                {
                    ...this.state,
                    layout: layout_data,
                })
        }
        else {
            alert('Please select a row to remove')
        }
    }

    async layoutInputHandler(e, row_number, quill=false, image_number = null) {
        if (quill == false ) {
            this.setState({
                layout: update(this.state.layout, {
                    [row_number]: {
                        [e.target.name]: { $set: e.target.value }
                    }
                })
            }, function () {
                console.log(this.state.layout)
            })
        } else {
            this.setState({
                layout: update(this.state.layout, {
                    [row_number]: {
                        'quote': { $set: e }
                    }
                })
            })
        }
      
        
    }

    onDrop(acceptedFiles, rejected, index, featured = false) {
        var that = this
        if (rejected.length > 0) {
            this.setState({ file_rejected: true })
            setTimeout(
                function () {
                    this.setState({ file_rejected: false });
                }
                    .bind(this),
                5000
            );
        }
        if (featured == 'featured') {
            if (acceptedFiles.length > 1) {
                return
            }
        }
        console.log(acceptedFiles)
        console.log(index)
        acceptedFiles.map((file, file_index) => {
            let data = new FormData();
            console.log(file)
            data.append('file', file)
            const reader = new FileReader();
            reader.onload = (e) => {
                var img = new Image();
                img.src = e.target.result;
                var image_src = e.target.result;
                img.onload = function () {
                    that.setState(featured == false ? {
                        layout: update(that.state.layout, {
                            [index]: {
                                image: {
                                    $push: [{
                                        src: image_src, id: Math.random(),
                                        name: file.name,
                                    }]
                                },
                                image_dimensions: {
                                    $push: [{
                                        width: this.width,
                                        height: this.height,
                                    }]
                                }
                            }
                        })
                    } : {
                        featured_content: [{ name: file.name, id: Math.random(), src: image_src }],
                        featured_content_width: this.width, featured_content_height: this.height
                    })
                }
            }
            this.uploadContent(data)
            reader.readAsDataURL(file);
            return (file);
        })
    }

    deleteImage(index, image_index, featured = false) {
        // console.log(variant_index, image_index)
        this.setState(featured == false ? {
            layout: update(this.state.layout, {
                [index]: {
                    image: {
                        $splice: [[image_index, 1]]
                    },
                    image_dimensions: {
                        $splice: [[image_index, 1]]
                    }
                }
            })
        } : this.setState({ featured_content: [], featured_content_width: 0, featured_content_height: 0 }))
    }

    moveImage(dragIndex, hoverIndex, index) {
        // const draggedImage = this.state.images[dragIndex]
        let layout_data = this.state.layout[index]
        let row_images_data = (layout_data.image)
        let row_images_dimensions_data = (layout_data.image_dimensions)
        const draggedImage = row_images_data[dragIndex]
        const draggedImageDimension = row_images_dimensions_data[dragIndex]
        this.setState({
            layout: update(this.state.layout, {
                [index]: {
                    image: { $splice: [[dragIndex, 1], [hoverIndex, 0, draggedImage]] },
                    image_dimensions: { $splice: [[dragIndex, 1], [hoverIndex, 0, draggedImageDimension]] }
                }
            })
        }, function () { console.log(this.state.layout) })
    }

    handleOnDragEnd(result) {
        this.setState({ dragging: false, dragIndex: null })
        if (!result.destination) {
            return
        }
        const layout_data = this.state.layout
        const [re_ordered_layout] = layout_data.splice(result.source.index, 1)
        layout_data.splice(result.destination.index, 0, re_ordered_layout)
    }

    openUploadDialog(index) {
        if (document.querySelectorAll('.dropzone-input')[index]) {
            document.querySelectorAll('.dropzone-input')[index].click()
        } else {
            alert(`Please choose a type of content for row ${index}`)
        }
        console.log(index)
        console.log(document.querySelectorAll('.dropzone-input')[index])

    }

    async uploadContent(data) {
        // const options = {
        //     onUploadProgress: (ProgressEvent) => {
        //         const { loaded, total } = ProgressEvent;
        //         let percent = Math.floor(loaded * 100 / total)
        //         console.log(`${loaded}kb of ${total}kb | ${percent}%`)
        //         if (percent < 100) {
        //             this.setState({uploadPercent: percent})
        //         }
        //     }
        // }
        axios.post(`${process.env.REACT_APP_POST_URL}/projects/upload_content`, data).then(async (res) => {
            if (res.status == 200) {
                console.log('Upload successful')
            }
            else if (res.status == 413) {
                alert('Try uploading a file size under 3mb')
            }
            else {
                alert('Error')
            }
        })
    }

    project_data() {
        axios
            .post(`${process.env.REACT_APP_API_URL}/projects/project_details`, {
                url: this.state.project_name,
            })
            .then((res, err) => {
                if (!err) {
                    if (res.data[0]["0"] != undefined) {
                        this.setState({
                            data: res.data,
                            project_uuid: res.data[0]["0"].uuid,
                            title: res.data[0]["0"].title,
                            subtitle: res.data[0]["0"].subtitle,
                            layout: JSON.parse(res.data[0]["0"].front_end_layout),
                            featured_content: [{ name: res.data[0]["0"].images, id: Math.random() }],
                            featured_content_width: res.data[0]["0"].featured_content_width,
                            featured_content_height: res.data[0]["0"].featured_content_height,
                            blur_hash: res.data[0]["0"].blur_hash,
                            type_of_work: res.data[0]["0"].type_of_work,
                            type_of_client: res.data[0]["0"].type_of_client,
                            number_of_rows: JSON.parse(res.data[0]["0"].front_end_layout).length,
                        });
                    }
                    else {
                    }
                }
                else {
                    window.location = '/projects'
                }
            });
    }

    get_tags() {
        axios
            .post(`${process.env.REACT_APP_API_URL}/tags`, {
                tag_type: '',
            })
            .then((res) => {
                this.setState({
                    type_of_work_list: res.data.filter(function (tag) {
                        return tag.type == 'Work'
                    }).sort((a, b) => a.sort_index - b.sort_index),
                    type_of_client_list: res.data.filter(function (tag) {
                        return tag.type == 'Client'
                    }).sort((a, b) => a.sort_index - b.sort_index)
                });
            });
    }

    saveProject() {
        let data = {
            uuid: this.state.project_uuid,
            title: this.state.title,
            sub_title: this.state.subtitle,
            type_of_client: this.state.type_of_client,
            type_of_work: this.state.type_of_work,
            featured_content_value: this.state.featured_content.length == 0 ? '' : this.state.featured_content[0].name,
            featured_content_width: this.state.featured_content_width,
            featured_content_height: this.state.featured_content_height,
            blur_hash: this.state.blur_hash,
            story: '',
            images: '',
            client: '',
            team: '',
            partner: ''
        };
        var layout_data = this.state.layout
        layout_data.map((row, index) => {
            row.image.forEach(function (image) {
                delete image.src
            })
        })
        data.front_end_layout = JSON.stringify(layout_data)

        const options = {
            onUploadProgress: (ProgressEvent) => {
                const { loaded, total } = ProgressEvent;
                let percent = Math.floor(loaded * 100 / total)
                console.log(percent)
                if (percent < 100) {
                    this.setState({ saveProgress: percent })
                }
            }
        }

        axios.post(`${process.env.REACT_APP_POST_URL}/projects/add_project`, data).then((res) => {
            if (res.status == 200) {
                this.setState({ project_uuid: res.data.uuid, notification_visible: true, success: true, api_response_message: 'Saved successfully' }, () => {
                    this.props.history.push({
                        search: "?" + `name=${res.data.url}`
                    })
                })
                setTimeout(() => {
                    this.setState({ notification_visible: false, api_response_message: '' })
                }, 3000)
            } else {
                this.setState({ notification_visible: true, success: false, api_response_message: res.statusText })
            }
        }).catch((err) => {
            console.log(err)
            this.setState({ notification_visible: true, success: false, api_response_message: 'Internal server error' })
        })
    }

    async createTag(e, source) {
        this.uploadTag(e, source)
        if (source == 'Work') {
            this.setState({ type_of_work: e }, function () {
                this.state.type_of_work_list.push({ value: e, label: e })
            })
        }
        else if (source == 'Client') {
            this.setState({ type_of_client: e }, function () {
                this.state.type_of_client_list.push({ value: e, label: e })
            })
        }
    }

    async uploadTag(name, tag_type) {
        axios.post(`${process.env.REACT_APP_POST_URL}/tags/add_tag`, { tag_name: name, tag_url: '', tag_type: tag_type }).then((res) => {
            if (res.status == 200) {
                console.log(res)
            }
        })
    }

    validateYouTubeUrl(index) {
        var url = this.state.layout[index].video;
        if (url != undefined || url != '') {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = url.match(regExp);
            if (match && match[2].length == 11) {
                console.log('Link is valid')
            } else {
                alert('Please enter a valid YouTube link');
            }
        }
    }

  youtubeVideoInputPrefixHandler() {
 
  }


    render() {
        var title = ''
        this.state.project_uuid != undefined ? title = 'Edit Project — Flybot Studios' : title = 'Add Project — Flybot Studios'
        return (
            <div className="content--wrapper">
                <div className="progress__bar" style={{ width: `${this.state.saveProgress} + '%'` }}></div>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <div className="main--container cms-container">
                    <NotificationBar style={{ position: 'absolute', width: '100%', top: '0', left: '0', right: '0' }} visible={this.state.notification_visible} success={this.state.success}>{this.state.api_response_message}</NotificationBar>
                    <div className="workFeatureHeader">
                        <div className="workFeatureHeader__title">
                            <h1><input autocomplete="off" value={this.state.project_uuid != undefined ? this.state.title : null} onInput={(e) => { this.setState({ title: e.target.value }); }} className="post_input_field" id="workFeatureTitle" type="text" name="title" placeholder="Title" /></h1>
                            <h5 className="workFeature__category project-category__select">
                                <div className="mb-3">
                                    <Creatable
                                        onCreateOption={(e) => this.createTag(e, 'Work')}
                                        styles={customStyles}
                                        placeholder={'Type of work'}
                                        id="type_of_work"
                                        value={this.state.type_of_work != '' ? { value: this.state.type_of_work, label: this.state.type_of_work } : undefined}
                                        onChange={(e) => this.setState({ type_of_work: e.value })}
                                        options={this.state.type_of_work_list.map((tag, index) => {
                                            return { value: tag.name, label: tag.name }
                                        })}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <Creatable
                                        onCreateOption={(e) => this.createTag(e, 'Client')}
                                        styles={customStyles}
                                        placeholder={'Type of client'}
                                        id="type_of_client"
                                        value={this.state.type_of_client != '' ? { value: this.state.type_of_client, label: this.state.type_of_client } : undefined}
                                        onChange={(e) => this.setState({ type_of_client: e.value })}
                                        options={this.state.type_of_client_list.map((tag, index) => {
                                            return { value: tag.name, label: tag.name }
                                        })}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                    />
                                </div>
                            </h5>
                        </div>
                        <div className="workFeatureHeader__subtitle">
                            {/* <h4><textarea autocomplete="off" value={this.state.project_uuid != undefined ? this.state.subtitle : null} onInput={(e) => { this.setState({ subtitle: e.target.value }) }} className="post_input_field" id="workFeatureSubTitle" type="text" name="subtitle" placeholder="Sub Title" /></h4> */}
                            {/* <RichTextEditor toolbarConfig={toolbarConfig} value={this.state.project_uuid != undefined ? this.state.subtitle : RichTextEditor.createEmptyValue()} onChange={(e) => this.setState({ subtitle: e })} /> */}
                            <ReactQuill modules={this.modules} formats={this.formats} placeholder={'Sub Title'} value={this.state.subtitle}
                                onChange={(e) => this.setState({subtitle: e})} />
                        </div>
                    </div>
                    <div className="row_wrapper">
                        <div className="row_body">
                            <DropzoneComponent index={`featured_content`} dropzone_open_index={this.state.dropzone_open_index} content_exists={this.state.featured_content.length !== 0 ? true : false} noClick={true} maxSize={3145728} onDrop={(acceptedFiles, rejected) => { this.onDrop(acceptedFiles, rejected, 0, 'featured') }}>
                                <DndProvider backend={HTML5Backend}>
                                    <ImageList featured={true} row_index={0} deleteImage={this.deleteImage.bind(this)} images={this.state.featured_content} />
                                </DndProvider>
                            </DropzoneComponent>
                        </div>
                        <div className="row_footer">
                            <div className="right-btn-placement">
                                <SecondaryButton   onClick={(e) => this.setState({ dropzone_open_index: 'featured_content' }, () => this.setState({ dropzone_open_index: null }))}>Add Content</SecondaryButton>
                                <SecondaryButton onClick={(e) => this.addRow(e)}>Add Row</SecondaryButton>
                            </div>
                        </div>
                    </div>
                    <div className="workFeatureDetail">
                        <DragDropContext onDragStart={(result) => { this.setState({ dragging: true, dragIndex: result.draggableId }) }} onDragEnd={(result) => this.handleOnDragEnd(result)}>
                            <Droppable droppableId="content-rows">
                                {
                                    (provided) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {
                                                this.state.layout.map((row, index) => {
                                                    return (
                                                        <Draggable key={index} draggableId={`${index}`} index={index}>
                                                            {
                                                                (provided) => (
                                                                    <div onClick={(e) => this.setState({ row_selected: true, selected_row_number: index, })} {...provided.draggableProps} ref={provided.innerRef}>
                                                                        <div style={{ boxShadow: this.state.dragging == true && this.state.dragIndex == index ? '0px 0px 7px 5px rgba(0,0,0,0.1)' : 'none' }} className="row_wrapper" active={this.state.selected_row_number == index ? true : false}>
                                                                            <div className="row_header" style={{ background: this.state.dragging == true && this.state.dragIndex == index ? '#f6f8fa' : 'transparent' }}>
                                                                                <div style={{ width: '100%', textAlign: 'center' }} {...provided.dragHandleProps}>
                                                                                    <svg fill={this.state.dragging == true && this.state.dragIndex == index ? '#006AFF' : 'darkgray'} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-drag-horizontal" width={30} height={30} viewBox="0 0 24 24"><path d="M3,15V13H5V15H3M3,11V9H5V11H3M7,15V13H9V15H7M7,11V9H9V11H7M11,15V13H13V15H11M11,11V9H13V11H11M15,15V13H17V15H15M15,11V9H17V11H15M19,15V13H21V15H19M19,11V9H21V11H19Z" /></svg>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row_body">
                                                                                {
                                                                                    row.content == 'image' ?
                                                                                        (
                                                                                            <React.Fragment>
                                                                                                <DropzoneComponent  index={index} dropzone_open_index={this.state.dropzone_open_index} content_exists={row.image.length != 0 ? true : false} maxFiles={
                                                                                                    row.image_type == 'full_row_content' ? 1 : row.image_type == 'single_column_content' ? 1 :
                                                                                                        row.image_type == 'double_column_content' ? 2 : row.image_type == 'triple_column_content' ? 3 : 0
                                                                                                } noClick={true} maxSize={3145728} onDrop={(acceptedFiles, rejected) => { this.onDrop(acceptedFiles, rejected, index) }}>
                                                                                                    <DndProvider backend={HTML5Backend}>
                                                                                                        <ImageList row_index={index} deleteImage={this.deleteImage.bind(this)} images={row.image} moveImage={(dragIndex, hoverIndex) => this.moveImage(dragIndex, hoverIndex, index)} />
                                                                                                    </DndProvider>
                                                                                                </DropzoneComponent>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                        : row.content == 'quote' ?
                                                                                            (
                                                                                                // <textarea autoComplete="off" type="text" className="post_input_field" placeholder="Enter Tagline" value={row.quote} name="quote" onInput={(e) => this.layoutInputHandler(e, index)} />
                                                                                                <ReactQuill modules={this.modules} formats={this.formats} placeholder={'Enter Tagline'} value={row.quote} name="quote" onChange={(e) => this.layoutInputHandler(e, index, true)} />
                                                                                            )

                                                                                            : row.content == 'paragraph' ?
                                                                                                (
                                                                                                    // <textarea autoComplete="off" type="text" className="post_input_field" placeholder="Enter Paragraph" value={row.quote} name="quote" onInput={(e) => this.layoutInputHandler(e, index)} />
                                                                                                    <ReactQuill modules={this.modules} formats={this.formats} placeholder={'Enter Paragraph'} value={row.quote} name="quote" onChange={(e) => this.layoutInputHandler(e, index, true)} />
                                                                                                )
                                                                                                : row.content == 'video' ?
                                                                                                    (
                                                                                                        <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                                                                                                            <span className="post_input_field" style={{position: 'absolute', top: '0', width: 'unset', border: '0'}}>
                                                                                                            https://youtu.be/
                                                                                                            </span>
                                                                                                             <textarea style={{textIndent: '103px'}}
                                                                                                           autoComplete="off" data-prefix="https://youtu.be/" type="text" className="post_input_field withprefix"  value={row.video} name="video" onInput={(e) => {this.layoutInputHandler(e, index)}}/>
                                                                                                        </div>
                                                                                                       
                                                                                                    )
                                                                                                    :
                                                                                                    null
                                                                                }
                                                                            </div>
                                                                            <div className="row_footer">
                                                                                {
                                                                                    this.state.selected_row_number == index &&
                                                                                    <React.Fragment>
                                                                                        <div className="content-select-container">
                                                                                            <Select
                                                                                                menuPlacement="auto"
                                                                                                styles={customStyles}
                                                                                                menuPortalTarget={document.body}
                                                                                                // styles={{ menu: base => ({ ...base, position: 'relative' }) }}
                                                                                                placeholder={'Select content type'}
                                                                                                id="content_select"
                                                                                                value={{
                                                                                                    value: row.image_type, label:
                                                                                                        row.image_type == 'full_row_content' ? 'Image' :
                                                                                                            row.image_type == 'single_column_content' ? 'Single column content' :
                                                                                                                row.image_type == 'double_column_content' ? 'Double column content' :
                                                                                                                    row.image_type == 'triple_column_content' ? 'Triple column content' :
                                                                                                                        row.image_type == 'multiple_content' ? 'Multiple content' :
                                                                                                                            row.image_type == '' ? 'Tagline' : row.image_type == 'n/a' ? 'Text' :
                                                                                                                                row.image_type == 'motion' ? 'Youtube Video' : 'Select content type'
                                                                                                }}
                                                                                                onChange={(e) => this.setState({
                                                                                                    layout: update(this.state.layout, {
                                                                                                        [index]: {
                                                                                                            image_type: { $set: e.value },
                                                                                                            content: { $set: e.label != 'Tagline' ? e.label != 'Text' ? e.label == 'Youtube Video' ? 'video' : 'image' : 'paragraph' : 'quote' }
                                                                                                        }
                                                                                                    })
                                                                                                }, () => {
                                                                                                    if (e.label == 'Youtube Video') {
                                                                                                        this.youtubeVideoInputPrefixHandler()
                                                                                                    }
                                                                                                })}
                                                                                                options={options}
                                                                                                components={{
                                                                                                    IndicatorSeparator: () => null
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="row_action_container">
                                                                                            <a onClick={() => this.removeRow(index)} className="trash-btn">
                                                                                                <svg viewBox="-40 0 427 427.00131" xmlns="http://www.w3.org/2000/svg"><path d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" /><path d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" /><path d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0" /><path d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" /></svg>
                                                                                            </a>
                                                                                            {
                                                                                                row.content !== 'quote' && row.content !== 'paragraph' && row.content !== '' && row.content !== 'video' &&
                                                                                                <SecondaryButton   onClick={(e) => this.setState({ dropzone_open_index: index }, () => this.setState({ dropzone_open_index: null }))}>Add Content</SecondaryButton>
                                                                                            }

                                                                                            <SecondaryButton onClick={(e) => this.addRow(e)}>Add Row</SecondaryButton>
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </Draggable>
                                                    )
                                                }
                                                )
                                            }
                                            {provided.placeholder}

                                        </div>
                                    )
                                }
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
                <PrimaryButton onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'}); this.saveProject()}} style={{ position: 'fixed', bottom: '5%', right: '5%' }}>Save</PrimaryButton>
            </div>
        );
    }
}

export default withRouter(AddProject)