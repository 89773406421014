import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { Helmet } from "react-helmet";
import { PrimaryButton } from "../Elements/primary-button";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from "react-select";
import { PopOver } from '../Elements/popover';
import Modal from '../Elements/modal';
import { SecondaryButton } from "../Elements/secondary-button";
import { NotificationBar } from "../Elements/notification-bar";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import FormLayout from '../Elements/form-layout';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';
import DropzoneComponent from '../Elements/dropzone';

const customStyles = {
  container: (provided, state) => (
    {
      ...provided,
      width: '100%',
      height: `${state.selectProps.price_selection == true ? 'unset' : '100%'}`,
    }
  ),
  control: (provided, state) => (
    {
      ...provided,
      height: `${state.selectProps.price_selection == true ? 'unset' : '100%'}`,
      borderRadius: '0',
      borderBottom: '0',
      border: '0',
      backgroundColor: state.selectProps.price_selection == true ? 'transparent' : 'transparent'
    }
  ),
  valueContainer: (provided, state) => (
    {
      ...provided,
      padding: '0 0 0 16px',

    }
  ),
  indicatorSeperator: (provided, state) => (
    {
      ...provided,
      display: 'none',
      width: '0'
    }
  ),
  menu: (provided, state) => (
    {
      ...provided,
      margin: '0',
      boxShadow: 'none',
      borderRadius: '0',
      border: '1px solid #006AFF',
      borderTop: '0',
      width: 'calc(100% + 2px)',
      left: '-1px'
    }
  ),
  menuList: (provided, state) => (
    {
      ...provided,
      padding: '0',
      borderRadius: '0'
    }
  ),
  singleValue: (provided, state) => (
    {
      ...provided,
      fontWeight: state.selectProps.price_selection == true ? '500' : 'initial'
    }
  )
}

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      modal_visible: null,
      notification_visible: false,
      api_response_message: '',
      success: false,
      team: [],
      file_rejected: false,
      old_password: '',
      new_password: '',
      confirm_password: ''
    };
  }

  get_team() {
    axios.post(`${process.env.REACT_APP_API_URL}/team`).then((res) => {
      this.setState({team: res.data.length > 0 ? res.data : [{
        uuid: uuidv4(),
        name: '',
        designation: '',
        role: '',
        biography: '',
        avatar: ''
      }]})
    })
  }

  update_password() {
    let data = {
        old_password: this.state.old_password,
        new_password: this.state.new_password,
        confirm_password: this.state.confirm_password
    }
    if (this.state.new_password !== this.state.confirm_password) {
        this.setState({notification_visible: true, success: false, api_response_message: 'Passwords do not match'}, () => {
            setTimeout(() => {
                this.setState({notification_visible: false, api_response_message: ''})
            }, 3000)
        })
    }

    axios.post(`${process.env.REACT_APP_POST_URL}/update_password`, data).then((res) => {
        if (res.status == 200) {
            this.setState({notification_visible: true, success: true, api_response_message: 'Password updated successfully'}, () => {
                setTimeout(() => {
                    this.setState({notification_visible: false, api_response_message: ''})
                }, 3000)
            })
        }
    }).catch((err) => {
        this.setState({notification_visible: true, success: false, api_response_message: 'Internal server error'}, () => {
            setTimeout(() => {
                this.setState({notification_visible: false, api_response_message: ''})
            }, 3000)
        })
    })

  }


  onDrop(acceptedFiles, rejected, index, featured = false) {
    var that = this
    if (rejected.length > 0) {
        this.setState({ file_rejected: true })
        setTimeout(
            function () {
                this.setState({ file_rejected: false });
            }
                .bind(this),
            5000
        );
    }
    if (featured == 'featured') {
        if (acceptedFiles.length > 1) {
            return
        }
    }
    // console.log(acceptedFiles)
    // console.log(index)
    acceptedFiles.map((file, file_index) => {
        let data = new FormData();
        console.log(file)
        var file_name = file.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "-")
        data.append('file', file, file_name)
        const reader = new FileReader();
        reader.onload = (e) => {
            var img = new Image();
            img.src = e.target.result;
            var image_src = e.target.result;
            img.onload = function () {
                that.setState({ team: update(that.state.team, {
                  [index]: {
                    avatar: {$set: file_name}
                  }
                })
                })
            }
        }
        this.uploadContent(data)
        reader.readAsDataURL(file);
        return (file);
    })
}

async uploadContent(data) {
  // const options = {
  //     onUploadProgress: (ProgressEvent) => {
  //         const { loaded, total } = ProgressEvent;
  //         let percent = Math.floor(loaded * 100 / total)
  //         console.log(`${loaded}kb of ${total}kb | ${percent}%`)
  //         if (percent < 100) {
  //             this.setState({uploadPercent: percent})
  //         }
  //     }
  // }
  axios.post(`${process.env.REACT_APP_POST_URL}/projects/upload_content`, data).then(async (res) => {
      if (res.status == 200) {
          console.log('Upload successful')
      }
      else if (res.status == 413) {
          alert('Try uploading a file size under 3mb')
      }
      else {
          alert('Error')
      }
  })
}

remove(uuid) {
  axios.post(`${process.env.REACT_APP_POST_URL}/remove`, {uuid: uuid, table: 'team'}).then((res) =>{
    console.log(res)
  })
}

  componentDidMount() {
    this.get_team()
    this.modules = {
      toolbar: [
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        ['link'],
      ],
    }
   
    this.formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'link'
    ]
  }


  componentWillUnmount() {

  }

  onClickHandler() {
    this.setState({ post_input_visible: true })
  }
  editHandler(e) {
    console.log(e.target.parentElement)
    var parentElement = e.target.parentElement
    parentElement.closest('div').previousSibling.querySelector('.item__title').classList.add('d-none')
    parentElement.closest('div').previousSibling.querySelector('.project-form-field').classList.remove('d-none')
    parentElement.closest('div').previousSibling.querySelector('.project-form-field').focus()
  }
  onChangeHandler(e, uuid) {
    if (e.target.value.trim() == '') {
      e.target.classList.add('d-none')
      e.target.parentElement.querySelector('.item__title').classList.remove('d-none')
    }
    else {
      if (window.confirm('Are you sure you want to save your changes?')) {
        axios.post(`${process.env.REACT_APP_POST_URL}/carousel/add_carousel`, { project_name: e.target.value, uuid: uuid }).then((res) => {
          if (res.status == 200) {
            console.log(res)
            window.location = ''
          }
          if (!res.length > 0) {
            window.location = ''
          }

        })
      }
    }
  }

  handleOnDragEnd(result, source) {
    if (!result.destination) {
      return
    }
    console.log(result)
    const carousel_list = this.state.carousel
    const highlights_list = this.state.highlights
    if (source == 'carousel') {
      const [re_ordered_carousel_list] = carousel_list.splice(result.source.index, 1)
      carousel_list.splice(result.destination.index, 0, re_ordered_carousel_list)
      var ordered_list_props = []
      this.setState({ carousel: carousel_list }, function () {
        this.state.carousel.map((carousel, index) => {
          ordered_list_props.push({ uuid: carousel.carousel.uuid, index: index })
        })
        this.updateSort(ordered_list_props, source)
      })
    }
    else if (source == 'projects') {
      const [re_ordered_highlights_list] = highlights_list.splice(result.source.index, 1)
      highlights_list.splice(result.destination.index, 0, re_ordered_highlights_list)
      var ordered_list_props = []
      this.setState({ highlights: highlights_list }, function () {
        this.state.highlights.map((highlight, index) => {
          ordered_list_props.push({ uuid: highlight.uuid, index: index })
        })
        this.updateSort(ordered_list_props, source)
      })
    }
  }

  async updateSort(list_props, source) {
    await axios.post(`${process.env.REACT_APP_POST_URL}/sort_order`, { table: source, list_props: list_props }).then((res) => {
      if (res.status == 200) {
        console.log('Sorted')
      }
      else {
        console.log('Error while sorting list')
      }
    }).catch(e => {
      console.log(e)
    })
  }

  modalHandler(index) {
    this.setState({ modal_visible: this.state.modal_visible !== index ? index : null })
  }


  render() {
    var title = 'Settings — Flybot Studios'
    return (
      <div className="content--wrapper">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="main--container">
        <NotificationBar style={{position: 'absolute', width: '100%', top: '0', left: '0', right: '0', zIndex: '999'}} visible={this.state.notification_visible} success={this.state.success}>{this.state.api_response_message}</NotificationBar>
          <div style={{ borderBottom: '1px solid #e3e4e5' }} className="newsHeader">
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'space-between' }} className="newsHeader__title">
              <div className="page_heading_wrapper">
                <h1 style={{ margin: '0' }}>Settings</h1>

              </div>
            
            </div>
          </div>
          <div className="home-section-container">
          {

                <React.Fragment>
                   <div>
                   <fieldset className="form-fieldset">
            <legend className="form-legend">
            Change Password
            {/* {
              team_index > 0 &&
              <button style={{marginLeft: '10px'}} onClick={() => this.setState({team: update(this.state.team, {
                $splice: [[team_index,1]]
              })}, () => {if (team.created_at !== undefined) {this.remove(team.uuid)}})} className="remove-button"><svg height="20px" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336" /><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa" /></svg></button>
            } */}
            </legend>
                <div className="form-grid">
                <FormLayout>
                    <FormLayout.FormRow>
                      <FormLayout.InputLabel>Current password</FormLayout.InputLabel>
                      <FormLayout.InputField  onInput={(e) => this.setState({old_password: e.target.value})} type="password" placeholder={`Enter current password`} />
                    </FormLayout.FormRow>
                    <FormLayout.FormRow>
                      <FormLayout.InputLabel>New password</FormLayout.InputLabel>
                      <FormLayout.InputField  onInput={(e) => this.setState({new_password: e.target.value})}type="password" placeholder={`Enter new password`} />
                    </FormLayout.FormRow>
                    <FormLayout.FormRow>
                      <FormLayout.InputLabel>Confirm password</FormLayout.InputLabel>
                      <FormLayout.InputField  onInput={(e) => this.setState({confirm_password: e.target.value})} type="password" placeholder={`Confirm password`} />
                    </FormLayout.FormRow>
                  </FormLayout>
                  
                </div>
          </fieldset>
                  </div>
                   {

<fieldset className="form-fieldset">
<div style={{width: '100%'}}>
    <div style={{ border: 'none' }} className="form-field">
        <div style={{ display: 'block', background: 'transparent', padding: '0', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', maxWidth: '633px', justifyContent: 'flex-end'}}>
            <PrimaryButton onClick={() => this.update_password()}>Save</PrimaryButton>
              
            </div>
        </div>
    </div>
</div>
</fieldset>
                }
                </React.Fragment>
               

            
          }
          </div>
         
        </div>
      </div>
    )
  }
}

export default Settings;