import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const type = "Image"; // Need to pass which type element can be draggable

const Image = ({ image, index, moveImage, deleteImage, row_index, featured}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: type,
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Move the content
      moveImage(dragIndex, hoverIndex);
      // Update the index for dragged item directly to avoid flickering when half dragged
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type, id: image.id, index },
    type: type,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  // initialize drag and drop into the element
  drag(drop(ref));
  return (
    <div
      ref={ref}
      style={{ opacity: isDragging ? 0 : 1, position: 'relative'}}
      className="file-item"
    >
      {
        image.name !== "" &&
        <React.Fragment>
           <img src={!image.src ? `${process.env.REACT_APP_ASSETS_URL}/${image.name}` : image.src} className="file-img" />
      <div style={{ position: 'absolute', width: '100%', top: '0', textAlign: 'right', padding: '1%', zIndex: '1' }}>
        <button style={{ position: 'relative', right: '-10px', top: '-3px' }} onClick={() => featured == true ? deleteImage(row_index, index, featured) : deleteImage(row_index, index)} className="remove-button">
          <svg height="20px" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336" /><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa" /></svg>
        </button>
      </div>
        </React.Fragment>
      }
    </div>
  );
};

const ImageList = ({ images, moveImage, deleteImage, row_index, featured }) => {
  const renderImage = (image, index) => {
    return (
      <Image
        image={image}
        index={index}
        row_index={row_index}
        key={image.id}
        deleteImage={deleteImage}
        moveImage={moveImage}
        featured={featured}
      />
    );
  };

  return <section className="file-list">{(images).map(renderImage)}</section>;
};

export default ImageList;
