import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import {PrimaryButton} from './primary-button';
const ModalCurtain = styled.div`
display: flex;
flex-flow: column;
justify-content: center;
align-items: center;
position: fixed;
margin: 0 !important;
top: 0;
bottom: 0;
left: 0;
right: 0;
padding: 60px;
backdrop-filter: blur(20px);
background: rgba(0,0,0,.4);
z-index: 999999;
@media(max-width: 600px) {
    padding: 18px
}
`

const ModalWrapper = styled.div`
display: flex;
flex-flow: column;
width: ${props => props.customer_modal == true ? '70%' : '600px'};
background-color: #fff;
border-radius: 12px;
z-index: 800;
overflow: hidden;
transform: translateZ(0);
@media(max-width: 600px) {
    width: 100%;
}
`

const ModalHeader = styled.div`
display: flex;
align-items: center;
position: relative;
padding: 12px 16px;
font-size: 24px;
font-weight: 700;
line-height: 1.333;
letter-spacing: 0.003em;
border-bottom: 1px solid rgba(0,0,0,.05);
@media(max-width: 600px) {
    font-size: 18px;
}
`

const ModalSecondaryHeader = styled.div`
display: flex;
align-items: center;
position: relative;
padding: 12px 24px;
font-size: 16px;
line-height: 1.333;
letter-spacing: 0.003em;
border-bottom: 1px solid rgba(0,0,0,.05);
justify-content: space-between;
.modal-header__col {
    width: 50%;
}
@media(max-width: 600px) {
    font-size: 16px;
    flex-direction: column;
    .modal-header__col {
        width: 100%;
    }
}
`

const ModalBody = styled.div`
flex: 0 1 auto;
padding: 24px;
overflow-y: auto;
`
const ModalFooter = styled.div`
flex: none;
padding: 12px 24px;
border-top: 1px solid rgba(0,0,0,.05);
zoom: 1;
@media(max-width: 600px) {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    flex-wrap: wrap;
    padding: 12px;
    &div, button {
        width: 100%;
        margin: 0 !important;
    }
}
`

const Modal = ({children, shown, close, customer_modal}) => {
    const [isBrowser, setIsBrowser] = useState(false);

    useEffect(() => {
        setIsBrowser(true)
    }, []);

    let subComponentList = Object.keys(Modal);

    let subComponents = subComponentList.map((key) => {
        return React.Children.map(children, (child) => {
            return child.type.displayName == key ? child : null
        }
        );
    });

    const modalContent =  shown ? (
        <React.Fragment>
            <ModalCurtain onClick={() => close()}>
                <ModalWrapper customer_modal={customer_modal} onClick={(e) => {e.stopPropagation()}}>
                {subComponents.map((component) => component)}
                </ModalWrapper>
            </ModalCurtain>
        </React.Fragment>
    ) : null

    if (isBrowser) {
        return ReactDOM.createPortal(
        modalContent,
        document.getElementById("modal-root")
            )
    } else {
        return null
    }
};

const Header = (props) => <ModalHeader>{props.children}</ModalHeader>
Header.displayName = 'Header'
Modal.Header = Header

const SecondaryHeader = (props) => <ModalSecondaryHeader>{props.children}</ModalSecondaryHeader>
SecondaryHeader.displayName = 'SecondaryHeader'
Modal.SecondaryHeader = SecondaryHeader

const Body = (props) => <ModalBody>{props.children}</ModalBody>
Body.displayName = 'Body'
Modal.Body = Body

const Footer = (props) => <ModalFooter>{props.children}</ModalFooter>
Footer.displayName = 'Footer'
Modal.Footer = Footer

export default Modal;