import React from "react";
import styled from 'styled-components';
const Button = styled.button`
position: relative;
display: inline-flex;
align-items: center;
justify-content: center;
height: 48px;
padding: 0 16px;
border-radius:  6px;
background-color:rgba(0,0,0,.05);
text-align: center;
font-weight: 500;
transition: background-color .2s ease-in-out,color .2s ease-in-out,opacity .2s ease-in-out;
user-select: none;
cursor: pointer;
color: rgba(0,0,0,.55);
border: none;
outline: none;
&:hover {
    background: #CCE1FF;
    color: rgba(0,0,0,.55);
};
&:focus {
    border: none;
    outline:none
}
`

export const SecondaryButton = ({children, className, style, onClick}) => {
    return <Button onClick={onClick} style={style} className={className}>{children}</Button>
}


