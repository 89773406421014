import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import { Helmet } from "react-helmet";
import { PrimaryButton } from "../Elements/primary-button";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from "react-select";
import { PopOver } from '../Elements/popover';
import Modal from '../Elements/modal';
import { SecondaryButton } from "../Elements/secondary-button";
import { NotificationBar } from "../Elements/notification-bar";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import FormLayout from '../Elements/form-layout';
import update from 'immutability-helper';
import { v4 as uuidv4 } from 'uuid';
import DropzoneComponent from '../Elements/dropzone';
import 'video-react/dist/video-react.css'; // import css
import { Player } from 'video-react';

const customStyles = {
  container: (provided, state) => (
    {
      ...provided,
      width: '100%',
      height: `${state.selectProps.price_selection == true ? 'unset' : '100%'}`,
    }
  ),
  control: (provided, state) => (
    {
      ...provided,
      height: `${state.selectProps.price_selection == true ? 'unset' : '100%'}`,
      borderRadius: '0',
      borderBottom: '0',
      border: '0',
      backgroundColor: state.selectProps.price_selection == true ? 'transparent' : 'transparent'
    }
  ),
  valueContainer: (provided, state) => (
    {
      ...provided,
      padding: '0 0 0 16px',

    }
  ),
  indicatorSeperator: (provided, state) => (
    {
      ...provided,
      display: 'none',
      width: '0'
    }
  ),
  menu: (provided, state) => (
    {
      ...provided,
      margin: '0',
      boxShadow: 'none',
      borderRadius: '0',
      border: '1px solid #006AFF',
      borderTop: '0',
      width: 'calc(100% + 2px)',
      left: '-1px'
    }
  ),
  menuList: (provided, state) => (
    {
      ...provided,
      padding: '0',
      borderRadius: '0'
    }
  ),
  singleValue: (provided, state) => (
    {
      ...provided,
      fontWeight: state.selectProps.price_selection == true ? '500' : 'initial'
    }
  )
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: undefined,
      projects: [],
      highlights: [],
      project_name: undefined,
      post_input_visible: false,
      upload_type: '',
      carousel: [],
      edit_carousel: false,
      edit_highlighted_project: false,
      options: [],
      modal_visible: null,
      notification_visible: false,
      api_response_message: '',
      success: false,
      about: [],
      testimonials: [],
      showreel: [],
      file_rejected: false,
      intro_video: ''
    };
  }
  get_projects() {
    axios.post(`${process.env.REACT_APP_API_URL}/projects`, { url: '' }).then((res) => {
      this.setState({
        projects: res.data[0], options: res.data[0].map((item, index) => {
          return { value: item.title, label: item.title }
        })
      });
      console.log(this.state.options);
    });
  }
  get_highlights() {
    axios.post(`${process.env.REACT_APP_API_URL}/highlights`).then((res) => {
      this.setState({ highlights: res.data });
    });
  }
  get_carousel() {
    axios
      .post(`${process.env.REACT_APP_API_URL}/carousel`)
      .then((res) => {
        this.setState({
          carousel: res.data
        });
        console.log(this.state.carousel)
      });
  }

  get_about() {
    axios.post(`${process.env.REACT_APP_API_URL}/about`).then((res) => {
      this.setState({about: res.data}, () => {
        console.log(this.state.about)
      })
    })
  }

  update_about() {
    let data = {
      uuid: this.state.about.length > 0 ? this.state.about[0].uuid : undefined,
      content_link: this.state.about.length > 0 ? this.state.about[0].content_link : '',
      title: this.state.about.length > 0 ? this.state.about[0].title : '',
      description: this.state.about.length > 0 ? this.state.about[0].description : ''
    }
    axios.post(`${process.env.REACT_APP_POST_URL}/update_about`, data).then((res) => {
     console.log(res)
    })
  }

  get_testimonials() {
    axios.post(`${process.env.REACT_APP_API_URL}/testimonials`).then((res) => {
      this.setState({testimonials: res.data.length > 0 ? res.data : [{
        uuid: uuidv4(),
        name: '',
        organization: '',
        quote: '',
        image: ''
      }]})
    })
  }

  update_testimonials() {
    let data = this.state.testimonials.map((testimonial) => {
      return {
        uuid: testimonial.uuid,
        name: testimonial.name,
        organization: testimonial.organization,
        quote: testimonial.quote,
        image: testimonial.image
      }
    })

    axios.post(`${process.env.REACT_APP_POST_URL}/update_testimonials`, {testimonials: data}).then((res) => {
      if (res.status == 200) {
        window.scrollTo(0,0)
        this.setState({notification_visible: true, api_response_message: 'Testimonials updated succssfully', success: true})
      } else {
        this.setState({notification_visible: true, api_response_message: 'Internal server error', success: false})
      }
    }).catch((e) => {
      this.setState({notification_visible: true, api_response_message: 'Internal server error', success: false})
      console.log(e)
    })
  }

  get_showreel() {
    axios.post(`${process.env.REACT_APP_API_URL}/showreel`).then((res) => {
      this.setState({showreel: res.data.length > 0 ? res.data : [{
        uuid: uuidv4(),
        link: ''
      }]})
    })
  }

  update_showreel() {
    let data = this.state.showreel.map((showreel) => {
      return {
        uuid: showreel.uuid,
        link: showreel.link
      }
    })
    axios.post(`${process.env.REACT_APP_POST_URL}/update_showreel`, {showreel: data}).then((res) => {
      if (res.status == 200) {
        window.scrollTo(0,0)
        this.setState({notification_visible: true, api_response_message: 'Showreel updated succssfully', success: true})
      } else {
        this.setState({notification_visible: true, api_response_message: 'Internal server error', success: false})
      }
    }).catch((e) => {
      this.setState({notification_visible: true, api_response_message: 'Internal server error', success: false})
      console.log(e)
    })
  }

  onDrop(acceptedFiles, rejected, index, featured = false) {
    var that = this
    if (rejected.length > 0) {
        this.setState({ file_rejected: true })
        setTimeout(
            function () {
                this.setState({ file_rejected: false });
            }
                .bind(this),
            5000
        );
    }
    if (featured == 'featured') {
        if (acceptedFiles.length > 1) {
            return
        }
    }
    console.log(acceptedFiles)
    console.log(index)
    acceptedFiles.map((file, file_index) => {
        let data = new FormData();
        console.log(file)
        data.append('file', file)
        const reader = new FileReader();
        reader.onload = (e) => {
            var img = new Image();
            img.src = e.target.result;
            var image_src = e.target.result;
            img.onload = function () {
                that.setState({ testimonials: update(that.state.testimonials, {
                  [index]: {
                    image: {$set: file.name}
                  }
                })
                })
            }
        }
        this.uploadContent(data)
        reader.readAsDataURL(file);
        return (file);
    })
}

videoUploadHandler = (acceptedFiles, rejected, index, featured = false) => {
  var that = this
    if (rejected.length > 0) {
        this.setState({ file_rejected: true, notification_visible: true, api_response_message: 'Please upload a file with size less than 5mb', success: false})
        setTimeout(
            function () {
                this.setState({ file_rejected: false, notification_visible: false, api_response_message: '' });
            }
                .bind(this),
            5000
        );
    }

    console.log(acceptedFiles)
    console.log(index)
    acceptedFiles.map((file, file_index) => {
        let data = new FormData();
        console.log(file)
        data.append('file', file)
        const reader = new FileReader();
        reader.onload = (e) => {
          // this.setState({about: update(this.state.about, {
          //   [0]: {
          //     content_link: {$set: file.name}
          //   }
          // })})
        }
         axios.post(`${process.env.REACT_APP_POST_URL}/projects/upload_content`, data).then(async (res) => {
          if (res.status == 200) {
            console.log(res.data)
            this.setState({about: update(this.state.about, {
              [0]: {
                content_link: {$set: res.data.file_name}
              }
            })}, () => {
              this.update_about()
            })
          }
          else if (res.status == 413) {
              alert('Try uploading a file size under 5mb')
          }
          else {
              alert('Error')
          }
      })
        reader.readAsDataURL(file);
        return (file);
    })
};

async uploadContent(data) {
  // const options = {
  //     onUploadProgress: (ProgressEvent) => {
  //         const { loaded, total } = ProgressEvent;
  //         let percent = Math.floor(loaded * 100 / total)
  //         console.log(`${loaded}kb of ${total}kb | ${percent}%`)
  //         if (percent < 100) {
  //             this.setState({uploadPercent: percent})
  //         }
  //     }
  // }
  await axios.post(`${process.env.REACT_APP_POST_URL}/projects/upload_content`, data).then(async (res) => {
      if (res.status == 200) {
        console.log(res.data)
        return res.data.file_name
      }
      else if (res.status == 413) {
          alert('Try uploading a file size under 3mb')
      }
      else {
          alert('Error')
      }
  })
}

  componentDidMount() {
    this.get_projects()
    this.get_carousel()
    this.get_highlights()
    this.get_about()
    this.get_showreel()
    this.get_testimonials()
    this.modules = {
      toolbar: [
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        ['link'],
      ],
    }
   
    this.formats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'link'
    ]
  }
  remove(uuid) {
    axios.post(`${process.env.REACT_APP_POST_URL}/remove`, { uuid: uuid, table: 'carousel' }).then((res) => {
      console.log(res)
      window.location = ''
    })
  }
  removeHighlight(uuid) {
    axios.post(`${process.env.REACT_APP_POST_URL}/remove_highlight`, { uuid: uuid, table: 'projects' }).then((res) => {
      console.log(res)
      window.location = ''
    })
  }
  componentWillUnmount() {

  }
  uploadCarousel() {
    console.log(this.state.project_name)
    axios.post(`${process.env.REACT_APP_POST_URL}/carousel/add_carousel`, { project_name: this.state.project_name.value, uuid: this.state.uuid }).then((res) => {
      if (res.status == 200) {
        this.setState({notification_visible: true, success: true, api_response_message: 'Saved successfully'})
        setTimeout(() => {
          this.setState({notification_visible: false}, () => {
            window.location = ''
          })
        }, 3000)
      }
      else {
        this.setState({notification_visible: true, success: false, api_response_message: res.statusText})
        setTimeout(() => {
          this.setState({notification_visible: false})
        }, 3000)
      }
    }).catch(err => {
      console.log(err)
      this.setState({notification_visible: true, success: false, api_response_message: 'Internal server error'})
        setTimeout(() => {
          this.setState({notification_visible: false})
        }, 3000)
    })
  }
  uploadHighlight() {
    axios.post(`${process.env.REACT_APP_POST_URL}/projects/highlighted_project`, { project_name: this.state.project_name.value }).then((res) => {
      if (res.status == 200) {
        this.setState({notification_visible: true, success: true, api_response_message: 'Saved successfully'})
        setTimeout(() => {
          this.setState({notification_visible: false}, () => {
          })
        }, 3000)
      } else {
        this.setState({notification_visible: true, success: false, api_response_message: res.statusText})
        setTimeout(() => {
          this.setState({notification_visible: false}, () => {
          })
        }, 3000)
      }
      // if (!res.length > 0) {
      //   window.location = ''
      // }
    }).catch((err) => {
      console.log(err)
      this.setState({notification_visible: true, success: false, api_response_message: 'Internal server error'})
        setTimeout(() => {
          this.setState({notification_visible: false})
        }, 3000)
    })
  }
  onClickHandler() {
    this.setState({ post_input_visible: true })
  }
  editHandler(e) {
    console.log(e.target.parentElement)
    var parentElement = e.target.parentElement
    parentElement.closest('div').previousSibling.querySelector('.item__title').classList.add('d-none')
    parentElement.closest('div').previousSibling.querySelector('.project-form-field').classList.remove('d-none')
    parentElement.closest('div').previousSibling.querySelector('.project-form-field').focus()
  }
  onChangeHandler(e, uuid) {
    if (e.target.value.trim() == '') {
      e.target.classList.add('d-none')
      e.target.parentElement.querySelector('.item__title').classList.remove('d-none')
    }
    else {
      if (window.confirm('Are you sure you want to save your changes?')) {
        axios.post(`${process.env.REACT_APP_POST_URL}/carousel/add_carousel`, { project_name: e.target.value, uuid: uuid }).then((res) => {
          if (res.status == 200) {
            console.log(res)
            window.location = ''
          }
          if (!res.length > 0) {
            window.location = ''
          }

        })
      }
    }
  }

  handleOnDragEnd(result, source) {
    if (!result.destination) {
      return
    }
    console.log(result)
    const carousel_list = this.state.carousel
    const highlights_list = this.state.highlights
    if (source == 'carousel') {
      const [re_ordered_carousel_list] = carousel_list.splice(result.source.index, 1)
      carousel_list.splice(result.destination.index, 0, re_ordered_carousel_list)
      var ordered_list_props = []
      this.setState({ carousel: carousel_list }, function () {
        this.state.carousel.map((carousel, index) => {
          ordered_list_props.push({ uuid: carousel.carousel.uuid, index: index })
        })
        this.updateSort(ordered_list_props, source)
      })
    }
    else if (source == 'projects') {
      const [re_ordered_highlights_list] = highlights_list.splice(result.source.index, 1)
      highlights_list.splice(result.destination.index, 0, re_ordered_highlights_list)
      var ordered_list_props = []
      this.setState({ highlights: highlights_list }, function () {
        this.state.highlights.map((highlight, index) => {
          ordered_list_props.push({ uuid: highlight.uuid, index: index })
        })
        this.updateSort(ordered_list_props, source)
      })
    }
  }

  async updateSort(list_props, source) {
    await axios.post(`${process.env.REACT_APP_POST_URL}/sort_order`, { table: source, list_props: list_props }).then((res) => {
      if (res.status == 200) {
        console.log('Sorted')
      }
      else {
        console.log('Error while sorting list')
      }
    }).catch(e => {
      console.log(e)
    })
  }

  modalHandler(index) {
    this.setState({ modal_visible: this.state.modal_visible !== index ? index : null })
  }


  render() {
    var title = 'Home — Flybot Studios'
    return (
      <div className="content--wrapper">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div className="main--container">
        <NotificationBar style={{position: 'absolute', width: '100%', top: '0', left: '0', right: '0', zIndex: '999'}} visible={this.state.notification_visible} success={this.state.success}>{this.state.api_response_message}</NotificationBar>
          <div style={{ borderBottom: '1px solid #e3e4e5' }} className="newsHeader">
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'space-between' }} className="newsHeader__title">
              <div className="page_heading_wrapper">
                <h1 style={{ margin: '0' }}>Home</h1>
                {/* <div className="page_heading_button_s_container">
                  {
                    this.state.project_name == undefined ?
                      (
                        <PrimaryButton onClick={() => this.onClickHandler()} type={'button'}>Add</PrimaryButton>
                      )
                      :
                      (
                        <PopOver function_parameter={false} option_one_function={this.uploadCarousel.bind(this)} option_two_function={this.uploadHighlight.bind(this)} options={[{ name: 'Add to Carousel' }, { name: 'Add to Highlight' }]} type={'button'}>Save</PopOver>
                      )
                  }

                </div> */}
              </div>
              {/* <div className="function_wrapper">
                {
                  this.state.post_input_visible ?
                    <div style={{ width: '100%' }} className="post_news_container">

                      <div style={{ paddingBottom: '2px', width: '100%' }} className="news-post-wrapper">
                    
                        <Select
                          styles={customStyles}
                          placeholder={'Select Project'}
                          id="carousel_select"
                          value={this.state.project_name}
                          onChange={(e) => this.setState({ project_name: e }, function () { console.log(this.state.project_name) })}
                          options={this.state.options}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                        />
                     
                      </div>

                    </div>
                    :
                    ''
                }
                <div className="page_heading_button_l_container" style={{ width: '100%', textAlign: 'right' }}>
                  {
                    this.state.project_name == undefined ?
                      (
                        <PrimaryButton onClick={() => this.onClickHandler()} type={'button'}>Add</PrimaryButton>
                      )
                      :
                      (
                        <PopOver function_parameter={false} option_one_function={this.uploadCarousel.bind(this)} option_two_function={this.uploadHighlight.bind(this)} options={[{ name: 'Add to Carousel' }, { name: 'Add to Highlight' }]} type={'button'}>Save</PopOver>
                      )
                  }

                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="tags-container">
            <div className="tags-wrapper">
              <div className="tag-header">
                <h4>Intro Video</h4>
              </div>
              <div className="numbered-list">
                <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, 'carousel')}>
                  <Droppable droppableId="carouselList">
                    {
                      (provided) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                          {
                            this.state.carousel.map((item, index) => {
                              return (
                                <Draggable key={index} draggableId={`${index}`} index={index}>
                                  {
                                    (provided) => (
                                      <div className="draggable-list-container" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                        <Modal shown={this.state.modal_visible == index ? true : false} close={() => this.modalHandler(index)}>
                                          <Modal.Header>Confirmation</Modal.Header>
                                          <Modal.Body>Are you sure you want to delete {item.title}?</Modal.Body>
                                          <Modal.Footer>
                                            <PrimaryButton onClick={() => this.remove(item.uuid)} style={{ float: 'right' }}>Save</PrimaryButton>
                                            <SecondaryButton onClick={() => { this.modalHandler(index) }} >Cancel</SecondaryButton>
                                          </Modal.Footer>
                                        </Modal>
                                        <li style={{ display: 'flex' }}>
                                          <span className="item__title">{item.projects.title}</span>
                                          <input list="projects" onBlur={(e) => this.onChangeHandler(e, item.projects.uuid)} type="text" className="project-form-field d-none" />
                                        </li>
                                        <div style={{ position: 'relative', whiteSpace: 'nowrap' }}>

                                          <button onClick={() => this.modalHandler(index)} className="remove-button"><svg height="20px" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336" /><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa" /></svg></button>
                                        </div>
                                      </div>
                                    )

                                  }

                                </Draggable>

                              )
                            })
                          }
                          {provided.placeholder}
                        </ul>
                      )
                    }
                  </Droppable>
                </DragDropContext>
              </div>

            </div>
            <div className="tags-wrapper">
              <div className="tag-header">
                <h4>Highlights</h4>
              </div>
              <div className="numbered-list">
                <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, 'projects')}>
                  <Droppable droppableId="highlightsList">
                    {
                      (provided) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                          {
                            this.state.highlights.map((value, index) => {
                              return (
                                <Draggable key={index} draggableId={`${index}`} index={index}>
                                  {
                                    (provided) => (
                                      <div className="draggable-list-container" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                        <Modal shown={this.state.modal_visible == index ? true : false} close={() => this.modalHandler(index)}>
                                          <Modal.Header>Confirmation</Modal.Header>
                                          <Modal.Body>Are you sure you want to delete {value.title}?</Modal.Body>
                                          <Modal.Footer>
                                            <PrimaryButton onClick={() => this.remove(value.uuid)} style={{ float: 'right' }}>Save</PrimaryButton>
                                            <SecondaryButton onClick={() => { this.modalHandler(index) }} >Cancel</SecondaryButton>
                                          </Modal.Footer>
                                        </Modal>
                                        <li>{value.title} </li>
                                        <button onClick={() => this.modalHandler(index)} className="remove-button"><svg height="20px" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336" /><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa" /></svg></button>
                                      </div>
                                    )
                                  }
                                </Draggable>
                              )
                            })
                          }
                          {provided.placeholder}
                        </ul>
                      )
                    }
                  </Droppable>
                </DragDropContext>
              </div>

            </div>
          </div> */}
          {/* <div className="tags_container">
          <div className="tags-wrapper">
              <div className="tag-header">
                <h4>About</h4>
              </div>
              <div className="numbered-list">
                <div>
                <h6>Title</h6>
                <ReactQuill modules={this.modules} formats={this.formats} placeholder={'Enter Title'} name="intro_title" />
                </div>
                <div>
                <h6>Description</h6>
                <ReactQuill modules={this.modules} formats={this.formats} placeholder={'Enter Title'} name="intro_description" />
                </div>
              </div>
            </div>
          </div> */}
          <div className="home-section-container">
          <fieldset className="form-fieldset">
            <legend className="form-legend">
            Intro Video
            </legend>
            <div  style={{width: '100%', maxWidth: '633px'}}>
            <Player
          playsInline
          src={`${process.env.REACT_APP_ASSETS_URL}/${this.state.about.length > 0 && this.state.about[0].content_link}` }
          fluid={true}
          muted={true}
          loop={true}
          autoPlay={true}
          controls={false}
      />
            </div>
         
       <fieldset style={{margin: 'unset'}} className="form-fieldset">
                            <div style={{width: '100%', maxWidth: '633px', marginTop: '1rem'}}>
                                <div style={{ border: 'none' }} className="form-field">
                                    <div style={{ display: 'block', background: 'transparent', padding: '0', width: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                          <DropzoneComponent textHidden={true} description={false} noClick={false} maxSize={5145728}  onDrop={(acceptedFiles, rejected) => { this.videoUploadHandler(acceptedFiles, rejected, 0)}}>
                                          <PrimaryButton type={'button'}>{'Upload'} </PrimaryButton>
                                          </DropzoneComponent>
                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
            {/* <div className="form-grid">
            <FormLayout>
                <FormLayout.FormRow>
                  <FormLayout.InputLabel>Vimeo Link</FormLayout.InputLabel>
                  <FormLayout.InputField onBlur={() => this.update_about()} onInput={(e) => this.setState({about: update(this.state.about, {
                    [0]: {
                      content_link: {$set: e.target.value}
                    }
                  })})} value={this.state.about.length > 0 ? this.state.about[0].content_link : ''}  type="text" placeholder={`Enter Vimeo Link`} />
                </FormLayout.FormRow>
              </FormLayout>
            </div> */}
          </fieldset>

          <fieldset className="form-fieldset">
            <legend className="form-legend">
            About
            </legend>
            <div className="form-grid">
            <FormLayout>
                <FormLayout.FormRow>
                  <FormLayout.InputLabel>Title</FormLayout.InputLabel>
                  <FormLayout.InputField onBlur={() => this.update_about()} onInput={(e) => this.setState({about: update(this.state.about, {
                    [0]: {
                      title: {$set: e.target.value}
                    }
                  })})} value={this.state.about.length > 0 ? this.state.about[0].title : ''} type="text" placeholder={`Enter Title`} />
                </FormLayout.FormRow>
                <FormLayout.FormRow>
                  <FormLayout.InputLabel>Description</FormLayout.InputLabel>
                  <FormLayout.TextAreaField onBlur={() => this.update_about()} onInput={(e) => this.setState({about: update(this.state.about, {
                    [0]: {
                      description: {$set: e.target.value}
                    }
                  })})} value={this.state.about.length > 0 ? this.state.about[0].description : ''}  type="text" placeholder={`Enter Description`} />
                </FormLayout.FormRow>
              </FormLayout>
            </div>
          </fieldset>
          <fieldset className="form-fieldset">
            <legend className="form-legend">
            Showreel
            </legend>
            <div className="form-grid">
            <FormLayout>
              {
                this.state.showreel.map((showreel, showreel_index) => {
                  return (
                <FormLayout.FormRow key={showreel_index}>
                  <FormLayout.InputLabel>Link</FormLayout.InputLabel>
                  <FormLayout.InputField onInput={(e) => this.setState({showreel: update(this.state.showreel, {
                    [showreel_index]: {
                      link: {$set: e.target.value}
                    }
                  })})} value={showreel.link} type="text" placeholder={`Enter Youtube Link`} />
                </FormLayout.FormRow>
                  )
                })
              }
              </FormLayout>
            </div>
            <fieldset style={{margin: 'unset'}} className="form-fieldset">
                            <div style={{width: '100%', maxWidth: '633px', marginTop: '1rem'}}>
                                <div style={{ border: 'none' }} className="form-field">
                                    <div style={{ display: 'block', background: 'transparent', padding: '0', width: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <a onClick={() => this.setState({showreel: update(this.state.showreel, {
                                              $push: [{
                                                uuid: uuidv4(),
                                                link: ''
                                              }]
                                            })})} style={{ padding: '12px 16px', textDecoration: 'none', border: '1px solid #d9d9d9', color: '#006AFF', width: '100%', cursor: 'pointer', fontWeight: '500', marginRight: '10px'}}>Add Showreel</a>
                                            <PrimaryButton onClick={() => this.update_showreel()}>Save</PrimaryButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
          </fieldset>
          <fieldset className="form-fieldset">
            <legend className="form-legend">
            Highlights
            </legend>
            <div className="form-grid">
            <div className="numbered-list">
                <DragDropContext onDragEnd={(result) => this.handleOnDragEnd(result, 'projects')}>
                  <Droppable droppableId="highlightsList">
                    {
                      (provided) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                          {
                            this.state.highlights.map((value, index) => {
                              return (
                                <Draggable key={index} draggableId={`${index}`} index={index}>
                                  {
                                    (provided) => (
                                      <div className="draggable-list-container" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                        <Modal shown={this.state.modal_visible == index ? true : false} close={() => this.modalHandler(index)}>
                                          <Modal.Header>Confirmation</Modal.Header>
                                          <Modal.Body>Are you sure you want to delete {value.title}?</Modal.Body>
                                          <Modal.Footer>
                                            <PrimaryButton onClick={() => this.removeHighlight(value.uuid)} style={{ float: 'right' }}>Save</PrimaryButton>
                                            <SecondaryButton onClick={() => { this.modalHandler(index) }} >Cancel</SecondaryButton>
                                          </Modal.Footer>
                                        </Modal>
                                        <li>{value.title == undefined ? <Select styles={customStyles}  placeholder={'Select Project'}
                          value={this.state.project_name}
                          onChange={(e) => this.setState({ project_name: e }, () => { this.uploadHighlight() })}
                          options={this.state.options}
                          components={{
                            IndicatorSeparator: () => null
                          }} /> : value.title} </li>
                                        <button onClick={() => this.modalHandler(index)} className="remove-button"><svg height="20px" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336" /><path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa" /></svg></button>
                                      </div>
                                    )
                                  }
                                </Draggable>
                              )
                            })
                          }
                          {provided.placeholder}
                        </ul>
                      )
                    }
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
                            <div style={{width: '100%', maxWidth: '633px', marginTop: '1rem'}}>
                                <div style={{ border: 'none' }} className="form-field">
                                    <div style={{ display: 'block', background: 'transparent', padding: '0', width: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                          <PrimaryButton onClick={() => this.setState({highlights: update(this.state.highlights, {
                                            $push: [{title: undefined}]
                                          })})} type={'button'}>{'Add'} </PrimaryButton>
                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
          </fieldset>
          
          {
            this.state.testimonials.map((testimonial, testimonial_index, arr) => {
              return (
                <fieldset key={testimonial_index} className="form-fieldset">
            <legend className="form-legend">
            {arr.length == 1 ? 'Testimonial' : `Testimonial ${testimonial_index + 1}`}
            </legend>
                <div className="form-grid">
                <FormLayout>
                    <FormLayout.FormRow>
                      <FormLayout.InputLabel>Name of Person</FormLayout.InputLabel>
                      <FormLayout.InputField value={testimonial.name} onInput={(e) => this.setState({testimonials: update(this.state.testimonials, {
                        [testimonial_index]: {
                          name: {$set: e.target.value}
                        }
                      })})} type="text" placeholder={`Enter Name`} />
                    </FormLayout.FormRow>
                    <FormLayout.FormRow>
                      <FormLayout.InputLabel>Organization</FormLayout.InputLabel>
                      <FormLayout.InputField onInput={(e) => this.setState({testimonials: update(this.state.testimonials, {
                        [testimonial_index]: {
                          organization: {$set: e.target.value}
                        }
                      })})} type="text" value={testimonial.organization} placeholder={`Enter Organization`} />
                    </FormLayout.FormRow>
                    <FormLayout.FormRow>
                      <FormLayout.InputLabel>Quote</FormLayout.InputLabel>
                      <FormLayout.TextAreaField onInput={(e) => this.setState({testimonials: update(this.state.testimonials, {
                        [testimonial_index]: {
                          quote: {$set: e.target.value}
                        }
                      })})} type="text" value={testimonial.quote} placeholder={`Enter Quote`}></FormLayout.TextAreaField>
                    </FormLayout.FormRow>
                  </FormLayout>
                  <DropzoneComponent textHidden={true} index={testimonial_index} description={false} noClick={false} maxSize={3145728}  onDrop={(acceptedFiles, rejected) => { this.onDrop(acceptedFiles, rejected, testimonial_index) }}>
                  <div style={{width: '140px', height: '140px',background: '#f2f2f2', border: '1px solid #d9d9d9', cursor: 'pointer', position: 'relative'}}>
                           <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', width: '100%', height: '100%'}}>
                           
                                
                                {
                                  testimonial.image !== '' &&
                                  (
                                     <img style={{width: '100%', height: '100%'}} src={`${process.env.REACT_APP_ASSETS_URL}/${testimonial.image}` } />
                                  )
                                  
                                    // <ImageIcon style={{width: '100%', height: '100%', transform: 'scale(0.5)'}} />
                                }
    
                           </div>
                        </div>
                  </DropzoneComponent>
                  
                </div>
                {
                  testimonial_index == arr.length - 1 &&
                  <fieldset style={{margin: 'unset'}} className="form-fieldset">
                            <div style={{width: '100%', maxWidth: '633px', marginTop: '1rem'}}>
                                <div style={{ border: 'none' }} className="form-field">
                                    <div style={{ display: 'block', background: 'transparent', padding: '0', width: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <a onClick={() => this.setState({testimonials: update(this.state.testimonials, {
                                              $push: [{
                                                uuid: uuidv4(),
                                                name: '',
                                                organization: '',
                                                quote: '',
                                                image: ''
                                              }]
                                            })})} style={{ padding: '12px 16px', color: '#000', textDecoration: 'none', border: '1px solid #d9d9d9', color: '#006AFF', width: '100%', cursor: 'pointer', fontWeight: '500', marginRight: '10px' }}>Add Testimonial</a>
                                            <PrimaryButton onClick={() => this.update_testimonials()}>Save</PrimaryButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                }
                
          </fieldset>
              )
            })
          }
          </div>
         
        </div>
      </div>
    )
  }
}

export default Home;